import centroid from "@turf/centroid";
import {
    Feature,
    lineString,
    LineString,
    polygon,
    Polygon,
    Properties,
} from "@turf/helpers";
import along from "@turf/along";
import kinks from "@turf/kinks";
import length from "@turf/length";
import lineIntersect from "@turf/line-intersect";

export type GeoJsonPolygon = Feature<Polygon, Properties>;
export type GeoJsonLine = Feature<LineString, Properties>;
/**
 * GoogleMapsのポリゴンをturfでGeoJSONのポリゴンに変換します。
 */
export const googleMapsPolygon2GeoJsonPolygon = (
    googlePolygon: google.maps.Polygon
) => {
    const polygonPath = googlePolygon.getPath();
    let turfPolygonPath = [];
    // turfのポリゴンは最初と最後に同じパスを設定する必要がある為、最初のパスを保存
    const firstPath = [
        polygonPath.getAt(0)["lng"](),
        polygonPath.getAt(0)["lat"](),
    ];
    googlePolygon.getPath().forEach((value, ii) => {
        let path = [
            polygonPath.getAt(ii)["lng"](),
            polygonPath.getAt(ii)["lat"](),
        ];
        // turf用のポリゴンパスを作成
        turfPolygonPath.push(path);
    });
    turfPolygonPath.push(firstPath);
    // GeoJSONポリゴンを返す
    return polygon([turfPolygonPath]);
};

/**
 * GoogleMapsのラインをturfでGeoJSONのラインに変換します。
 */
export const googleMapsLine2GeoJsonLine = (
    googleLine: google.maps.Polyline
) => {
    const linePath = googleLine.getPath();
    let turfLinePath: any = [];
    googleLine.getPath().forEach((value, ii) => {
        let path = [linePath.getAt(ii)["lng"](), linePath.getAt(ii)["lat"]()];
        // turf用のラインパスを作成
        turfLinePath.push(path);
    });
    // turf用ラインを返す
    return lineString(turfLinePath);
};

/**
 * turfのGeoJSONのポリゴンをGoogleMapsのポリゴンに変換します。
 */
export const GeoJsonPolygon2googleMapsPolygon = (
    tgtPolygon: GeoJsonPolygon
) => {
    const paths: any[] = [];
    tgtPolygon.geometry.coordinates[0].forEach((val) => {
        paths.push({ lat: val[1], lng: val[0] });
    });
    return new google.maps.Polygon({ paths });
};

/**
 * turfのGeoJSONのポリゴンをGoogleMapsのポリゴンに変換します。
 */
export const GeoJsonPolygon2googleMapsPolygonProperties = (
    tgtPolygon: GeoJsonPolygon
) => {
    const paths: any[] = [];
    tgtPolygon.properties?.coordinates[0].map((val: any) => {
        paths.push({ lat: val[1], lng: val[0] });
    });

    return new google.maps.Polygon({ paths });
};

/**
 * turfのGeoJSONのポリゴンをGoogleMapsのポリゴンに変換します。
 */
export const GeoJsonMultiPolygon2googleMapsPolygon = (
    tgtPolygon: GeoJsonPolygon,
    coordinatesKey: number
) => {
    const paths: any[] = [];
    tgtPolygon.geometry.coordinates[coordinatesKey].forEach((val) => {
        paths.push({ lat: val[1], lng: val[0] });
    });
    return new google.maps.Polygon({ paths });
};

/**
 * GoogleMapsまたはGeoJSONのポリゴンの中心点を返す
 */
export const getPolygonCenter = (
    tgtPolygon: google.maps.Polygon | GeoJsonPolygon
) => {
    const poly =
        tgtPolygon instanceof google.maps.Polygon
            ? googleMapsPolygon2GeoJsonPolygon(tgtPolygon)
            : tgtPolygon;
    // 中心地点を返す
    return centroid(poly);
};

/**
 * 2本の線が交差しているポイントを返す
 */
export const getLineIntersect = (
    tgtLine1: google.maps.Polyline | GeoJsonLine,
    tgtLine2: google.maps.Polyline | GeoJsonLine
) => {
    const line1 =
        tgtLine1 instanceof google.maps.Polyline
            ? googleMapsLine2GeoJsonLine(tgtLine1)
            : tgtLine1;
    const line2 =
        tgtLine2 instanceof google.maps.Polyline
            ? googleMapsLine2GeoJsonLine(tgtLine2)
            : tgtLine2;
    return lineIntersect(line1, line2);
};

/**
 * ポリゴンがよじれている時trueを返す
 */
export const booleanKinksPolygon = (
    tgtPolygon: google.maps.Polygon | GeoJsonPolygon
) => {
    const poly =
        tgtPolygon instanceof google.maps.Polygon
            ? googleMapsPolygon2GeoJsonPolygon(tgtPolygon)
            : tgtPolygon;
    const intersectPoints = kinks(poly);
    return intersectPoints.features.length > 0;
};

/**
 * latlngをpointに変換
 * @param {*} x
 * @param {*} y
 */
export const latLng2point = (
    latlng: google.maps.LatLng,
    map?: google.maps.Map
) => {
    if (map) {
        let projection = map.getProjection();
        if (projection) {
            let bounds = map.getBounds();
            if (bounds) {
                let topRight = projection.fromLatLngToPoint(
                    bounds.getNorthEast()
                );
                let bottomLeft = projection.fromLatLngToPoint(
                    bounds.getSouthWest()
                );
                let scale = Math.pow(2, map.getZoom()!);
                let worldPoint = projection?.fromLatLngToPoint(latlng);
                if (worldPoint) {
                    return {
                        x: Math.floor((worldPoint.x - bottomLeft!.x) * scale),
                        y: Math.floor((worldPoint.y - topRight!.y) * scale),
                    };
                }
            }
        }
    }
};

/**
 * ポリライン上のポイント位置を返す
 */
export const getPointOnLine = (
    line: google.maps.Polyline,
    distance = 5,
    direction = "start"
) => {
    const turfLine = googleMapsLine2GeoJsonLine(line);
    let len = distance / 1000;
    if (direction === "end") {
        len = length(turfLine) - len;
        if (len <= 0) {
            // 距離がマイナスになってしまう場合、半分の距離で設定する。
            len = length(turfLine) / 2;
        }
    }
    const point = along(turfLine, len);
    return new google.maps.LatLng(
        point.geometry.coordinates[1],
        point.geometry.coordinates[0]
    );
};

/**
 * 距離計算用の経路検索を行います。
 * 非同期処理の為、Promiseオブジェクトを返します。
 * @export
 */
export function getRouteDistance(
    origin: any,
    destination: any,
    travelMode: google.maps.TravelMode
) {
    return new Promise((resolve, reject) => {
        let request = {
            origin: origin,
            destination: destination,
            travelMode: travelMode,
            unitSystem: google.maps.UnitSystem.METRIC,
            provideRouteAlternatives: false,
            avoidHighways: true,
            avoidTolls: true,
        };

        let DirectionsService = new google.maps.DirectionsService();
        DirectionsService.route(request, function (results, status) {
            if (status === google.maps.DirectionsStatus.OK) {
                resolve(results);
            } else {
                reject(null);
            }
        });
    });
}

/**
 * LineのGeoJSONの長さを計算する
 * @param {*} geojson ラインのgeojson
 */
export const calcLineLengthGeoJson = (geojson: GeoJsonLine) => {
    return length(geojson, { units: "meters" });
};
