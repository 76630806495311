// 背景カラー
export const BGDefaultColor = "#FFFCD6";
export const BGChangeColor = "#FFFFFF";
export const BGDisabledColor = "rgba(0, 0, 0, 0.06)";
// テキストカラー
export const TextDefaultColor = "rgba(0, 0, 0)";
export const TextChangeColor = "rgba(0, 0, 255, 1)";
export const TextDisabledColor = "rgba(0, 0, 0, 0.38)";

interface tableType {
    [key: string]: any; // <-この行を追加!
}
export const table_name = {
    表紙: "location_cover",
    出店条件: "location_storeopeningconditions",
    商圏:"location_trade_area",
    寄付き: "location_donation",
    影響度: "location_impact",
    図面: "location_drawing",
} as tableType;
export const getTableName = (key: string) => {
    return key in table_name ? table_name[key] : "";
};
interface tableColumnType {
    [key: string]: any; // <-この行を追加!
}
// DBカラム名に置換テーブル
export const table_column_name = {
    location_cover: {
        address: "address",
        contractDueDate: "contract_due_date",
        openDueDate: "open_due_date",
        targetContractNotOpened: "target_contract_not_opened",
    },
    location_storeopeningconditions: {
        settingReason: "setting_reason",
        candidateLocationRuins: "candidate_location_ruins",
        candidateLocationRuinsLongText: "candidate_location_ruins_long_text",
        isStoreNameSelectReason: "is_store_name_select_reason",
        removalReason: "removal_Reason",
        open24Hours: "open24_hours",
        notOpen24Hours: "not_open24_hours",
        alcoholLicense: "alcohol_license",
        noAlcoholLicense: "no_alcohol_license",
        tobaccoLicence: "tobacco_licence",
        noTobaccoLicence: "no_tobacco_licence",
        areaType: "area_type",
        nearestAlreadystoreDistance: "nearest_already_store_distance",
        environmentType: "environment_type",
        acquisitionRequirement: "acquisition_requirement",
        tobaccoDistanceStandard: "tobacco_distance_standard",
        distanceStandardAlreadystoreCount:
            "distance_standard_alreadystore_count",
        previousJob: "previous_job",
        conflictCVS: "conflict_cvs",
        cvsLastNippan: "cvs_last_nippan",
        relocationDistance: "relocation_distance",
        ownershipType: "ownership_type",
        storeType: "store_type",
        storefrontage: "store_frontage",
        storeArea: "store_area",
        salsesArea: "salses_area",
        openCase: "open_case",
        nakajimaChilled: "nakajima_chilled",
        islandChilled: "island_chilled",
        gondolaUnits: "gondola_units",
        gondolaHeight: "gondola_height",
        iceCaseLarge: "ice_case_large",
        iceCaseMedium: "ice_case_medium",
        iceCaseSmall: "ice_case_small",
        compactStocker: "compact_stocker",
        lowTempReachIn: "low_temp_reach_in",
        walkIn: "walk_in",
        conterWidth: "conter_width",
        existingStoreName: "existing_store_name",
        storeNo: "store_no",
        averageNippan: "average_nippan",
        achievementAcquisitionStart: "achievement_acquisition_start",
        achievementAcquisitionEnd: "achievement_acquisition_end",
        receptionNo: "reception_no",
        sStoreOpenDate: "s_store_open_date",
        sStoreCloseDueDate: "s_store_close_due_date",
        closingDays: "closing_days",
        inSalesConstructionDays: "in_sales_construction_days",
        carCommonRatio: "car_common_ratio",
        personCommonRatio: "person_common_ratio",
        sStoreLineDistance: "s_store_line_distance",
        sStoreWayDistance: "s_store_way_distance",
        sStoreCarCustomerRatio: "s_store_car_customer_ratio",
        lastfixedMonth: "last_fixed_month",
        lastOneYearNippan: "last_one_year_nippan",
        yearOnYear: "year_on_year",
        yearMonth: "year_month",
        changeType: "change_type",
        content: "content",
        lastOneYearNippan0: "last_one_year_nippan_0",
        lastOneYearNippan1: "last_one_year_nippan_1",
        lastOneYearNippan2: "last_one_year_nippan_2",
        lastOneYearNippan3: "last_one_year_nippan_3",
        lastOneYearNippan4: "last_one_year_nippan_4",
        lastOneYearNippan5: "last_one_year_nippan_5",
        lastOneYearNippan6: "last_one_year_nippan_6",
        lastOneYearNippan7: "last_one_year_nippan_7",
        lastOneYearNippan8: "last_one_year_nippan_8",
        lastOneYearNippan9: "last_one_year_nippan_9",
        lastOneYearNippan10: "last_one_year_nippan_10",
        lastOneYearNippan11: "last_one_year_nippan_11",
        yearOnYear0: "year_on_year_0",
        yearOnYear1: "year_on_year_1",
        yearOnYear2: "year_on_year_2",
        yearOnYear3: "year_on_year_3",
        yearOnYear4: "year_on_year_4",
        yearOnYear5: "year_on_year_5",
        yearOnYear6: "year_on_year_6",
        yearOnYear7: "year_on_year_7",
        yearOnYear8: "year_on_year_8",
        yearOnYear9: "year_on_year_9",
        yearOnYear10: "year_on_year_10",
        yearOnYear11: "year_on_year_11",
        storeMoveType: "store_move_type",
        storeMoveDistance: "store_move_distance",
        storeTypeMultipleStore: "store_type_multiple_store",
    },
    location_trade_area: {
        realitySurveyHouseholdCount: "reality_survey_household_count",
        realitySurveyOffice: "reality_survey_office",
        realitySurveyEmployedWorkers: "reality_survey_employed_workers",
    },
    location_donation: {
        plan_area_id: "plan_area_id",
        branch: "branch",
        create_date: "create_date",
        update_date: "update_date",
        delete_flag: "delete_flag",
        drivingLanePositiveFrontage: "driving_lane_positive_frontage",
        drivingLanePositiveSideWayA: "driving_lane_positive_sideway_a",
        drivingLanePositiveSideWayB: "driving_lane_positive_sideway_b",
        drivingLanePositiveBackstreet: "driving_lane_positive_backstreet",
        drivingLaneOppositeFrontage: "driving_lane_opposite_frontage",
        drivingLaneOppositeSideWayA: "driving_lane_opposite_sideway_a",
        drivingLaneOppositeSideWayB: "driving_lane_opposite_sideway_b",
        drivingLaneOppositeBackstreet: "driving_lane_opposite_backstreet",
        medianStripSelect: "median_strip_select",
        medianStripSelect2: "median_strip_select2",
        medianStripSelect3: "median_strip_select3",
        medianStripSelect4: "median_strip_select4",
        medianStripHeight: "median_strip_height",
        medianStripHeight2: "median_strip_height2",
        medianStripHeight3: "median_strip_height3",
        medianStripHeight4: "median_strip_height4",
        sidewalkSelect: "sidewalk_select",
        sidewalkSelect2: "sidewalk_select2",
        sidewalkSelect3: "sidewalk_select3",
        sidewalkSelect4: "sidewalk_select4",
        sidewalkHeight: "sidewalk_height",
        sidewalkHeight2: "sidewalk_height2",
        sidewalkHeight3: "sidewalk_height3",
        sidewalkHeight4: "sidewalk_height4",
        roadWidthDoor: "road_width_door",
        roadWidthDoor2: "road_width_door2",
        roadWidthDoor3: "road_width_door3",
        roadWidthDoor4: "road_width_door4",
        roadWidthForward: "road_width_forward",
        roadWidthForward2: "road_width_forward2",
        roadWidthForward3: "road_width_forward3",
        roadWidthForward4: "road_width_forward4",
        roadWidthFrontLocation: "road_width_front_location",
        roadWidthFrontLocation2: "road_width_front_location2",
        roadWidthFrontLocation3: "road_width_front_location3",
        roadWidthFrontLocation4: "road_width_front_location4",
        slopeClimb: "slope_climb",
        slopeClimb2: "slope_climb2",
        slopeClimb3: "slope_climb3",
        slopeClimb4: "slope_climb4",
        slopeDescend: "slope_descend",
        slopeDescend2: "slope_descend2",
        slopeDescend3: "slope_descend3",
        slopeDescend4: "slope_descend4",
        trafficSignalEtcForwardSelect: "traffic_signal_etc_forward_select",
        trafficSignalEtcForwardSelect2: "traffic_signal_etc_forward_select2",
        trafficSignalEtcForwardSelect3: "traffic_signal_etc_forward_select3",
        trafficSignalEtcForwardSelect4: "traffic_signal_etc_forward_select4",
        trafficSignalEtcForwardDistance: "traffic_signal_etc_forward_distance",
        trafficSignalEtcForwardDistance2:
            "traffic_signal_etc_forward_distance2",
        trafficSignalEtcForwardDistance3:
            "traffic_signal_etc_forward_distance3",
        trafficSignalEtcForwardDistance4:
            "traffic_signal_etc_forward_distance4",
        trafficSignalEtcFrontLocationSelect:
            "traffic_signal_etc_front_location_select",
        trafficSignalEtcFrontLocationSelect2:
            "traffic_signal_etc_front_location_select2",
        trafficSignalEtcFrontLocationSelect3:
            "traffic_signal_etc_front_location_select3",
        trafficSignalEtcFrontLocationSelect4:
            "traffic_signal_etc_front_location_select4",
        trafficSignalEtcFrontLocationDistance:
            "traffic_signal_etc_front_location_distance",
        trafficSignalEtcFrontLocationDistance2:
            "traffic_signal_etc_front_location_distance2",
        trafficSignalEtcFrontLocationDistance3:
            "traffic_signal_etc_front_location_distance3",
        trafficSignalEtcFrontLocationDistance4:
            "traffic_signal_etc_front_location_distance4",
        visibilityFactorBuilding: "visibility_factor_building",
        visibilityFactorBuilding2: "visibility_factor_building2",
        visibilityFactorBuilding3: "visibility_factor_building3",
        visibilityFactorBuilding4: "visibility_factor_building4",
        visibilityFactorSignPost: "visibility_factor_sign_post",
        visibilityFactorSignPost2: "visibility_factor_sign_post2",
        visibilityFactorSignPost3: "visibility_factor_sign_post3",
        visibilityFactorSignPost4: "visibility_factor_sign_post4",
        parkingArea: "parking_area",
        parkingtsuboArea: "parkingtsubo_area",
        parkingCapacity: "parking_capacity",
        parkingTangentLineFrontage: "parking_tangent_line_frontage",
        parkingTangentLineSideWayA: "parking_tangent_line_sideway_a",
        parkingTangentLineSideWayB: "parking_tangent_line_sideway_b",
        parkingTangentLineBackstreet: "parking_tangent_line_backstreet",
        no1EntranceFrontage: "no1_entrance_frontage",
        no1EntranceSideWayA: "no1_entrance_sideway_a",
        no1EntranceSideWayB: "no1_entrance_sideway_b",
        no1EntranceBackstreet: "no1_entrance_backstreet",
        no1DepthFrontage: "no1_depth_frontage",
        no1DepthSideWayA: "no1_depth_sideway_a",
        no1DepthSideWayB: "no1_depth_sideway_b",
        no1DepthBackstreet: "no1_depth_backstreet",
        no2EntranceFrontage: "no2_entrance_frontage",
        no2EntranceSideWayA: "no2_entrance_sideway_a",
        no2EntranceSideWayB: "no2_entrance_sideway_b",
        no2EntranceBackstreet: "no2_entrance_backstreet",
        no2DepthFrontage: "no2_depth_frontage",
        no2DepthSideWayA: "no2_depth_sideway_a",
        no2DepthSideWayB: "no2_depth_sideway_b",
        no2DepthBackstreet: "no2_depth_backstreet",
        completeScheduleYear: "complete_schedule_year",
        completeScheduleMonth: "complete_schedule_month",
    },
    location_impact: {
        store_position: "store_position",
        common_road: "common_road",
        plan_median_strip: "plan_median_strip",
        store_median_strip: "store_median_strip",
        straight_count: "straight_count",
        turn_count: "turn_count",
        railroad_crossing: "railroad_crossing",
        river_width: "river_width",
        crossing_road_count: "crossing_road_count",
        conflict_sej: "conflict_sej",
        conflict_main_cvs: "conflict_main_cvs",
        conflict_other_cvs: "conflict_other_cvs",
        near_store_code: "near_store_code",
        average_common_ratio: "average_common_ratio",
        road_distance: "road_distance",
        salesfloor: "salesfloor",
        parkingarea: "parkingarea",
        car_front_store_count: "car_front_store_count",
        car_total_share_ratio: "car_total_share_ratio",
        people_front_store_count: "people_front_store_count",
        people_total_share_ratio: "people_total_share_ratio",
    },
    location_drawing: {
        plan_area_id: "plan_area_id",
        branch: "branch",
        create_date: "create_date",
        update_date: "update_date",
        delete_flag: "delete_flag",
        upload_file_paths: "upload_file_paths",
        isGreenGem: "is_green_gem",
        isGrave: "is_grave",
        isMedianStrip: "is_median_strip",
        isBusStopBlackout: "is_bus_stop_blackout",
        isInGreenGem: "is_in_green_gem",
        isOtherTenantParking: "is_other_tenant_parking",
        isExpresswayEtcPole: "is_expressway_etc_pole",
        isBusLaneEtc: "is_bus_lane_etc",
        isElectricityPole: "is_electricity_pole",
        isSidewalk: "is_sidewalk",
        isOneWayStreet: "is_one_way_street",
        isTramTrack: "is_tram_track",
        isElectricityPoleBranchLine: "is_electricity_pole_branch_line",
        isCrosswalk: "is_crosswalk",
        isNoEntryRoad: "is_no_entry_road",
        isParkingMeter: "is_parking_meter",
        isHighVoltageTowers: "is_high_voltage_towers",
        isGuardrail: "is_guardrail",
        isWaterwayEtc: "is_waterway_etc",
        isOthersPlace: "is_others_place",
        isHydrantEtc: "is_hydrant_etc",
        isStreetTreesEtc: "is_street_trees_etc",
        isRailroadCrossing: "is_railroad_crossing",
        isOldRoad: "is_old_road",
        isBillboardTowerEtc: "is_billboard_tower_etc",
        isStreetLightsEtc: "is_street_lights_etc",
        isBridge: "is_bridge",
        isUnpavedRoad: "is_unpaved_road",
        isGarbageDump: "is_garbage_dump",
        isRoadSigns: "is_road_signs",
        isOverpass: "is_overpass",
        isUnmaintainedEtc: "is_unmaintained_etc",
        isOther: "is_other",
        otherLongText: "other_long_text",
        HandDrawnLayout: "hand_drawn_layout",
        handDrawnLayoutSubTotal: "hand_drawn_layout_total",
        hand_drawn_layout_total_tsubo: "hand_drawn_layout_total_tsubo",
    },
} as tableColumnType;
export const getTableColumnName = (key: string, coulmn: string) => {
    return key in table_column_name ? table_column_name[key][coulmn] : "";
};
