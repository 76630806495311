import * as React from "react";

import { Box, Button, Checkbox, TextField } from "@mui/material";

export interface pageProps {
    props: {};
}

const Color: React.FC<pageProps> = ({ props }) => {
    const [labelName, setLabelName] = React.useState("サンプルラベル");
    const [sampleValue, setSampleValue] = React.useState("サンプル入力値");
    const [sampleColor, setSampleColor] = React.useState("FFFCD6");
    const [sampleCounterColor, setSampleCounterColor] = React.useState("FFFFFF");
    const [sampleBorderColor, setSampleBorderColor] = React.useState("00FF00");
    const [backgroundColor, setBackgroundColor] = React.useState("FFFFFF");
    const [checked, setChecked] = React.useState<boolean>(false);

    const resetHandle = () => {
        setLabelName("サンプルラベル");
        setSampleValue("サンプル入力値");
        setSampleColor("FFFCD6");
        setChecked(false);
    };

    const counterResetHandle = () => {
        setSampleCounterColor("FFFFFF");
        setSampleBorderColor("00FF00");
        setBackgroundColor("FFFFFF");
    };

    return (
        <Box
            style={{
                width: window.innerWidth,
                height: window.innerHeight,
            }}
        >
            <Box sx={{ display: 'flex' }}>
                <Box sx={{
                    width: window.innerWidth / 2,
                    height: window.innerHeight,
                    backgroundColor: "#F8FAF8",
                }}>
                    <Box
                        style={{
                            padding: "80px 0 0 60px",
                        }}
                    ><h3>立地調査サンプル</h3>
                        <Box sx={{ display: "flex", margin: "0 0 10px 0" }}>
                            <Box sx={{ width: "160px", margin: "16px 0" }}>サンプルラベル</Box>
                            <TextField
                                onChange={(e) => setLabelName(e.target.value)}
                                value={labelName}
                            ></TextField>
                        </Box>
                        <Box sx={{ display: "flex", margin: "0 0 10px 0" }}>
                            <Box sx={{ width: "160px", margin: "16px 0" }}>サンプル入力値</Box>
                            <TextField
                                onChange={(e) => setSampleValue(e.target.value)}
                                value={sampleValue}
                            ></TextField>
                        </Box>
                        <Box sx={{ display: "flex", margin: "0 0 10px 0" }}>
                            <Box sx={{ width: "160px", margin: "16px 0" }}>HTMLカラー</Box>
                            <TextField
                                onChange={(e) => setSampleColor(e.target.value)}
                                value={sampleColor}
                            ></TextField>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <Box sx={{ width: "160px", margin: "16px 0" }}>
                                {labelName}
                            </Box>
                            <TextField
                                sx={{
                                    "& .MuiOutlinedInput-root": {
                                        backgroundColor: checked
                                            ? "rgba(0, 0, 0, 0.06)"
                                            : "#" + sampleColor,
                                    },
                                }}
                                value={sampleValue}
                                disabled={checked}
                            ></TextField>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <Box sx={{ width: "160px", margin: "16px 0" }}>活性/非活性</Box>
                            <Checkbox
                                onChange={(e) => setChecked(e.target.checked)}
                                checked={checked}
                            ></Checkbox>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <Button
                                variant="contained"
                                color="success"
                                size="small"
                                onClick={resetHandle}
                            >リセット</Button>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{
                    width: window.innerWidth / 2,
                    height: window.innerHeight,
                    backgroundColor: "#" + backgroundColor,
                }}>
                    <Box
                        style={{
                            padding: "80px 0 0 60px",
                        }}
                    ><h3>カウンターサンプル</h3>
                        <Box sx={{ display: "flex", margin: "0 0 10px 0" }}>
                            <Box sx={{ width: "160px", margin: "16px 0" }}>ページ背景色</Box>
                            <TextField
                                onChange={(e) => setBackgroundColor(e.target.value)}
                                value={backgroundColor}
                            ></TextField>
                        </Box>
                        <Box sx={{ display: "flex", margin: "0 0 10px 0" }}>
                            <Box sx={{ width: "160px", margin: "16px 0" }}>ボタン背景色</Box>
                            <TextField
                                onChange={(e) => setSampleCounterColor(e.target.value)}
                                value={sampleCounterColor}
                            ></TextField>
                        </Box>
                        <Box sx={{ display: "flex", margin: "0 0 10px 0" }}>
                            <Box sx={{ width: "160px", margin: "16px 0" }}>ボタン枠色</Box>
                            <TextField
                                onChange={(e) => setSampleBorderColor(e.target.value)}
                                value={sampleBorderColor}
                            ></TextField>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <Box sx={{ width: "160px", margin: "16px 0" }}>カウンターボタン</Box>
                            <Box sx={{ display: "flex" }}>
                                <Box
                                    sx={{
                                        margin: '8px',
                                        width: '46px', maxWidth: '46px',
                                        height: '23px', maxHeight: '23px',
                                        textAlign: 'center', padding: '8px 0',
                                        border: '2px solid #' + sampleBorderColor,
                                        borderRadius: '8px',
                                        backgroundColor: '#' + sampleCounterColor
                                    }}
                                >0</Box>
                                <Box
                                    sx={{
                                        margin: '8px',
                                        width: '46px', maxWidth: '46px',
                                        height: '23px', maxHeight: '23px',
                                        textAlign: 'center', padding: '8px 0',
                                        border: '2px solid #' + sampleBorderColor,
                                        borderRadius: '8px',
                                        backgroundColor: '#' + sampleCounterColor
                                    }}
                                >0</Box>
                                <Box
                                    sx={{
                                        margin: '8px',
                                        width: '46px', maxWidth: '46px',
                                        height: '23px', maxHeight: '23px',
                                        textAlign: 'center', padding: '8px 0',
                                        border: '2px solid #' + sampleBorderColor,
                                        borderRadius: '8px',
                                        backgroundColor: '#' + sampleCounterColor
                                    }}
                                >0</Box>
                            </Box>
                        </Box>
                        <Box sx={{ display: "flex" }}>
                            <Button
                                variant="contained"
                                color="success"
                                size="small"
                                onClick={counterResetHandle}
                            >リセット</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Color;
