import * as React from "react";

import { useUserContext } from "../../../user-provider";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
    Button,
    TextField,
    FormLabel,
} from '@mui/material';
import {
    timeDisplay,
    borderStyle,
    borderStyleColor,
    headerStyle,
    headerStyleWithBorder,
    setParam,
    peopleAvg,
    peopleAddition,
    dateDisplayByType,
} from "./TrafficVolumeFunction";
import { LOCAL_CONSTANT } from "../../Const";
import { useUtilityContext } from "../../../utility-provider";
import { apiRequest } from "../../../api/ApiCall";
import ConfirmDialog from "../../components/ConfirmDialog";
import Comment from "../../components/Comment";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { groupSearchFunc } from "../../components/PermissionFunc";
import DateModal from "./DateModal";
import CountDate from "./CountDate";
import dayjs from 'dayjs';
import { setUnitEdit } from '../../../features/longPageSlice';
import { setPermissionFunc } from '../../components/PermissionFunc';
import MessageDialog from '../../components/MessageDialog';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ChangeTrafficVolume from './ChangeTrafficVolume';
import { locationTrafficObj } from '../../../data/type';

const dataListStyle = (width: number) => {
    return {
        width: width + '%',
        minWidth: width + '%',
        maxWidth: width + '%',
        padding: '0',
        '* p': { padding: '0' },
        '* input': { padding: '0' },
    }
}

const dataStyle = (width: number) => {
    return {
        width: width + '%',
        minWidth: width + '%',
        maxWidth: width + '%',
        padding: '0',
        '* p': { padding: '0' },
        '* input': { padding: '0' },
        borderLeft: "solid 2px rgba(100, 100, 100, 1)",
    }
}


const dataList = () => {
    return [
        {
            key: LOCAL_CONSTANT.LABEL.STUDENT,
            w: 12,
            h: 80,
            col: 1,
            inputKey: 'student',
        },
        {
            key: LOCAL_CONSTANT.LABEL.OVER_SIXTY_FIVE,
            w: 21,
            h: 40,
            col: 2,
            inputKey_male: 'male_65over',
            inputKey_female: 'female_65over',
        },
        {
            key: LOCAL_CONSTANT.LABEL.OTHER,
            w: 21,
            h: 40,
            col: 2,
            inputKey_male: 'male_other',
            inputKey_female: 'female_other',
        },
        {
            key: LOCAL_CONSTANT.LABEL.TOTAL,
            w: 13,
            h: 80,
            col: 1,
            inputKey: 'sumAll',
        },
        {
            key: LOCAL_CONSTANT.LABEL.BICYCLE_LINE_BREAK,
            w: 12,
            h: 80,
            col: 1,
            inputKey: 'bicycle',
        },
    ]
}

const editTrafficList = () => {
    return [
        LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_6.name,
        LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_7.name,
        LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_8.name,
        LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_9.name,
        LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_10.name,
        LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_12.name,
        LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_13.name,
    ]
}

export interface trafficVolumePeopleProps {
    props: {
        keyName: string;
        trafficType: string;
        trafficData: any;
        target: string;
        setReload: any;
        nightRatio: number;
        holidaysRatio: number;
        nightRatioValue: number;
        holidaysRatioValue: number;
        userType: string;
    }
}

const TrafficVolumePeople: React.FC<trafficVolumePeopleProps> = ({ props }) => {
        const utilityCtx = useUtilityContext();
        const dispatch = useAppDispatch();

        const [actionFlag, setActionFlag] = React.useState<boolean>(false)
        const [trafficData, setTrafficData] = React.useState([])
        const [originData, setOriginData] = React.useState([])
        const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(false);
        const [commentOpen, setCommentOpen] = React.useState<boolean>(false)
        const [edit, setEdit] = React.useState<boolean>(false)
        const [openDate, setOpenDate] = React.useState<boolean>(false);
        const [targetDate, setTargetDate] = React.useState<any>();
        const [openCountDate, setOpenCountDate] = React.useState<boolean>(false);
        const [targetCountDate, setTargetCountDate] = React.useState<any>();
        const objLongPageParam = useAppSelector((state) => state.longPage.param);
        // 単体保存ステータス
        const objUnitEdit = useAppSelector((state) => state.longPage.unitEdit);
        const [text, setText] = React.useState<string>("")
        const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
        const objLocationTraffic5mcut = useAppSelector((state) => state.locationTraffic5mcut.obj);

        const [userData, setUserData] = React.useState<any>({});
        const user = useUserContext();
        // 追加ボタン
        const [commentDisabled, setCommentDisabled] = React.useState<boolean>(true)
        const [editDisabled, setEditDisabled] = React.useState<boolean>(true)
        const [deleteDisabled, setDeleteDisabled] = React.useState<boolean>(true)
        const [message, setMessage] = React.useState<string>("");
        const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
        const [hasButton, setHasButton] = React.useState<boolean>(false);
        const [deleteType, setDeleteType] = React.useState<string>("")
        const [messageBody, setMessageBody] = React.useState<string>("")
        // 入替
        const [changeOpen, setChangeOpen] = React.useState<boolean>(false);
        const [targetTraffic, setTargetTraffic] = React.useState(locationTrafficObj)

        //ユーザー取得タイミングによる画面制御
        React.useEffect(() => {
            if (JSON.parse(user.userData || "null") !== null) {
                setUserData(JSON.parse(user.userData));
            }
        }, [user]);

        // テーブル表示非表示
        const [tableDisplay, setTableDisplay] = React.useState<string>("none")
        React.useEffect(() => {
            if (userData && objLocationBase.plan_area_id !== '') {
                // 不備コメント
                if (userData.department_code_OA === '992002' &&
                    userData.job_category_code_OA === '550' &&
                    groupSearchFunc(objLocationBase.task_id) === 'B'
                ) {
                    setCommentDisabled(false)
                }
                // 編集ボタン
                if (userData.job_category_code_OA === '360' && objLocationBase.task_id === 'A001') {
                    setEditDisabled(false)
                }
                // 削除ボタン
                if (userData.job_category_code_OA === '360' && objLocationBase.task_id === 'A001') {
                    setDeleteDisabled(false)
                }
            }
            const nightArr = ['1', '3']
            const holydaysArr = ['2', '3']
            if (userData && objLocationTraffic5mcut && objLocationTraffic5mcut.plan_area_id !== '') {
                switch (props.trafficType) {
                    case '6':   // 朝
                    case '7':   // 朝特定TR
                    case '8':   // 昼
                    case '9':   // 昼特定TR
                    case '12':  // 夕特定TR
                        setTableDisplay('none')
                        if (props.target === LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN) {
                            setTableDisplay('display')
                        } else {
                            if (objLocationTraffic5mcut.cut_survey === '1') {
                                setTableDisplay('display')
                            }
                        }
                        break
                    case '10':
                        // 夜
                        setTableDisplay('none')
                        if (props.target === LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN) {
                            if (nightArr.includes(objLocationTraffic5mcut.people_another_survey)) {
                                setTableDisplay('display')
                            }
                        } else {
                            if (
                                nightArr.includes(objLocationTraffic5mcut.people_another_survey) &&
                                objLocationTraffic5mcut.cut_survey === '1') {
                                setTableDisplay('display')
                            }
                        }
                        break
                    case '13':
                        // 土曜、休祝日又は行楽地のトップシーズン
                        setTableDisplay('none')
                        if (props.target === LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN) {
                            if (holydaysArr.includes(objLocationTraffic5mcut.people_another_survey)) {
                                setTableDisplay('display')
                            }
                        } else {
                            if (
                                holydaysArr.includes(objLocationTraffic5mcut.people_another_survey) &&
                                objLocationTraffic5mcut.cut_survey === '1') {
                                setTableDisplay('display')
                            }
                        }
                        break
                    case '11':  // 夜（モバ空）
                    case '14':  // 土曜、休祝日又は行楽地のトップシーズン（モバ空）
                        setTableDisplay('none')
                        if (props.target === LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN) {
                            if (
                                (userData.user_type === 'z003' || userData.department_code_OA === '992002')
                            ) {
                                setTableDisplay('display')
                            }
                        } else {
                            if (
                                (userData.user_type === 'z003' || userData.department_code_OA === '992002') &&
                                objLocationTraffic5mcut.cut_survey === '1'
                            ) {
                                setTableDisplay('display')
                            }
                        }
                        break
                    default:
                        break
                }
            }
        }, [props.trafficType, objLocationTraffic5mcut, userData, objLocationBase])

        React.useEffect(() => {
            const copyData = JSON.parse(JSON.stringify(props.trafficData))
            setTrafficData(copyData)
        }, [props.trafficData])

        const clickCommentOpen = () => {
            let text = LOCAL_CONSTANT.LABEL.PEOPLE_TRAFFIC + '（' + LOCAL_CONSTANT.LABEL.IN_FIVE + '）'
            if (props.target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN_N_OUT)) {
                text = LOCAL_CONSTANT.LABEL.PEOPLE_TRAFFIC + '（' + LOCAL_CONSTANT.LABEL.IN_OUT_FIVE + '）'
            } else if (props.target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_OUT)) {
                text = LOCAL_CONSTANT.LABEL.PEOPLE_TRAFFIC + '（' + LOCAL_CONSTANT.LABEL.OUT_FIVE + '）'
            }
            setText(text + " " + props.keyName)
            setCommentOpen(true)
        }

        const handleSaveComment = () => {
            setCommentOpen(false)
        }

        const deleteCancel = () => {
            setConfirmDialogOpen(false)
        }
        const onClickDelete = () => {
            setDeleteType("multiple")
            setMessageBody(props.keyName + "\n削除しますか？")
            setConfirmDialogOpen(true)
        }


        const changePeople = (row: any) => {
            setTargetTraffic(row)
            setChangeOpen(true)
        }

        const deletePeople = (row: any) => {
            setTargetTraffic(row)
            setDeleteType("single")
            setMessageBody(props.keyName + "\n削除しますか？")
            setConfirmDialogOpen(true)
        }


        const closeMessageDialog = () => {
            setMessage("");
            setHasButton(false);
            setMessageDialogOpen(false);
            setTargetTraffic(locationTrafficObj)
            setMessageBody("")
            setDeleteType("")
            // リロード
            props.setReload(true)
        };


        const setBody = () => {
            return props.keyName + "\n削除しますか？"
        }

        const deleteResult = () => {
            const count_type = trafficData.filter((row: any) => row.count_type === "3");
            let params = {}
            if (deleteType === 'single') {
                params = {
                    mode: "regist",
                    endPoint: "/location/Traffic/v1/single-delete",
                    query: {
                        planAreaId: objLongPageParam.planAreaId,
                        branch: objLongPageParam.branch,
                        trafficType: props.trafficType,
                        trafficTypeSeq: targetTraffic.traffic_type_seq,
                        count_type: count_type.length.toString()
                    }
                }
            } else {
                params = {
                    mode: "regist",
                    endPoint: "/location/Traffic/v1/delete",
                    query: {
                        planAreaId: objLongPageParam.planAreaId,
                        branch: objLongPageParam.branch,
                        trafficType: props.trafficType,
                        count_type: count_type.length.toString()
                    }
                }
            }
            if (utilityCtx.showSpinner) {
                utilityCtx.showSpinner();
            }
            (async () => {
                try {
                    setConfirmDialogOpen(false)
                    setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DELETE_START_TRAFFIC_PEOPLE + "\n" + props.keyName);
                    setMessageDialogOpen(true);
                    await apiRequest(params);
                    setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DELETE_TRAFFIC_PEOPLE + "\n" + props.keyName);
                    setHasButton(true);
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                } catch (e) {
                    console.log(e);
                    setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DELETE_ERROR_TRAFFIC_PEOPLE + "\n" + props.keyName);
                    setHasButton(true);
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                }
            })();
        }

        const openDateModal = (count_date: any) => {
            setTargetDate(count_date)
            setOpenDate(true)
        }

        const openCountDateModal = (count_date: any) => {
            setTargetCountDate(count_date)
            setOpenCountDate(true)
        }

        const setDate = (data: any, newData: any) => {
            data.count_date = dayjs(newData).format('YYYY-MM-DD HH:mm:ss').toString() + ".001" // マイクロ秒の追加
        }

        const setCountDate = (data: any, count_hour: string, count_minute: string, count_time: string) => {
            data.count_date = data.count_date.substring(0, 10)
                + ' ' + count_hour.padStart(2, "0")
                + ':' + count_minute.padStart(2, "0")
                + ':' + data.count_date.substring(17, 23)
            data.count_time = count_time
        }


        const checkMenuDisplay = () => {
            let display = 'flex'
            if ([
                LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_11.name,
                LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_14.name
            ].includes(props.keyName)
            ) {
                display = 'none'
            }
            // 5m内外はコメント・編集・削除ボタン不要
            if (props.target.includes(LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN_N_OUT)) {
                display = 'none'
            }

            return display
        }

        const getTargetLabel: any = (labelName: string) => {
            let targetLabel = "people_5m_in_" + labelName
            if (props.target.includes("out")) {
                targetLabel = "people_5m_out_" + labelName
            }
            return targetLabel;
        }

        const changeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, row: any, labelName: string) => {
            const regexRange0To9999 = /^(|0|[1-9][0-9]{0,3})$/;  //入力規則:整数 範囲:0～9999
            const targetValue = e.target.value;

            //(内)自転車の場合
            if (labelName.includes("bicycle")) {
                let total = 0; //合計値
                if (labelName.includes("in")) {
                    total += Number(row.people_5m_in_male_65over);
                    total += Number(row.people_5m_in_female_65over);
                    total += Number(row.people_5m_in_male_other);
                    total += Number(row.people_5m_in_female_other);
                    total += Number(row.people_5m_in_student);

                    if (total < Number(targetValue)) {
                        let message: any = LOCAL_CONSTANT.CONFIRM_MESSAGE.OVER_USER_INPUT_PEOPLE;
                        message = message.replace('__TOTAL__', total.toString());
                        message = message.replace('__BICYCLE__', targetValue.toString());
                        if (utilityCtx.showSnackbar) {
                            utilityCtx.showSnackbar("warning", message.slice(0, -1).split("\n").map((line: any, key: any) => <span key={key}>{line}<br /></span>));
                        }
                    } else {
                        if (regexRange0To9999.test(targetValue)) {
                            row[labelName] = targetValue;
                            setActionFlag(!actionFlag);
                        }
                    }
                } else if (labelName.includes("out")) {
                    total += Number(row.people_5m_out_male_65over);
                    total += Number(row.people_5m_out_female_65over);
                    total += Number(row.people_5m_out_male_other);
                    total += Number(row.people_5m_out_female_other);
                    total += Number(row.people_5m_out_student);

                    if (total < Number(targetValue)) {
                        let message: any = LOCAL_CONSTANT.CONFIRM_MESSAGE.OVER_USER_INPUT_PEOPLE;
                        message = message.replace('__TOTAL__', total.toString());
                        message = message.replace('__BICYCLE__', targetValue.toString());
                        if (utilityCtx.showSnackbar) {
                            utilityCtx.showSnackbar("warning", message.slice(0, -1).split("\n").map((line: any, key: any) => <span key={key}>{line}<br /></span>));
                        }
                    } else {
                        if (regexRange0To9999.test(targetValue)) {
                            row[labelName] = targetValue;
                            setActionFlag(!actionFlag);
                        }
                    }
                }
            } else {
                if (regexRange0To9999.test(targetValue)) {
                    row[labelName] = targetValue;
                    setActionFlag(!actionFlag);
                }
            }
        }

        const editStart = (target: string, trafficType: string) => {
            // 人通行量 編集フラグ更新
            if (target === 'in') {
                switch (trafficType) {
                    case '6': if (!objUnitEdit.TrafficType6In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType6In5m: true })); break; //人 5m内 朝
                    case '7': if (!objUnitEdit.TrafficType7In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType7In5m: true })); break; //人 5m内 朝特定TR
                    case '8': if (!objUnitEdit.TrafficType8In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType8In5m: true })); break; //人 5m内 昼
                    case '9': if (!objUnitEdit.TrafficType9In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType9In5m: true })); break; //人 5m内 昼特定TR
                    case '10': if (!objUnitEdit.TrafficType10In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType10In5m: true })); break; //人 5m内 夜
                    case '12': if (!objUnitEdit.TrafficType12In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType12In5m: true })); break; //人 5m内 夕特定TR
                    case '13': if (!objUnitEdit.TrafficType13In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType13In5m: true })); break; //人 5m内 土曜、休祝日又は行楽地のトップシーズン
                }
            } else if (target === 'out') {
                switch (trafficType) {
                    case '6': if (!objUnitEdit.TrafficType6Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType6Out5m: true })); break; //人 5m外 朝
                    case '7': if (!objUnitEdit.TrafficType7Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType7Out5m: true })); break; //人 5m外 朝特定TR
                    case '8': if (!objUnitEdit.TrafficType8Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType8Out5m: true })); break; //人 5m外 昼
                    case '9': if (!objUnitEdit.TrafficType9Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType9Out5m: true })); break; //人 5m外 昼特定TR
                    case '10': if (!objUnitEdit.TrafficType10Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType10Out5m: true })); break; //人 5m外 夜
                    case '12': if (!objUnitEdit.TrafficType12Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType12Out5m: true })); break; //人 5m外 夕特定TR
                    case '13': if (!objUnitEdit.TrafficType13Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType13Out5m: true })); break; //人 5m外 土曜、休祝日又は行楽地のトップシーズン
                }
            }
            setEdit(true)
            const copyData = JSON.parse(JSON.stringify(trafficData))
            setOriginData(copyData)
        }

        const editCancel = (target: string, trafficType: string) => {
            // 人通行量 編集フラグ更新
            if (target === 'in') {
                switch (trafficType) {
                    case '6': if (objUnitEdit.TrafficType6In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType6In5m: false })); break; //人 5m内 朝
                    case '7': if (objUnitEdit.TrafficType7In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType7In5m: false })); break; //人 5m内 朝特定TR
                    case '8': if (objUnitEdit.TrafficType8In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType8In5m: false })); break; //人 5m内 昼
                    case '9': if (objUnitEdit.TrafficType9In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType9In5m: false })); break; //人 5m内 昼特定TR
                    case '10': if (objUnitEdit.TrafficType10In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType10In5m: false })); break; //人 5m内 夜
                    case '12': if (objUnitEdit.TrafficType12In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType12In5m: false })); break; //人 5m内 夕特定TR
                    case '13': if (objUnitEdit.TrafficType13In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType13In5m: false })); break; //人 5m内 土曜、休祝日又は行楽地のトップシーズン
                }
            } else if (target === 'out') {
                switch (trafficType) {
                    case '6': if (objUnitEdit.TrafficType6Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType6Out5m: false })); break; //人 5m外 朝
                    case '7': if (objUnitEdit.TrafficType7Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType7Out5m: false })); break; //人 5m外 朝特定TR
                    case '8': if (objUnitEdit.TrafficType8Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType8Out5m: false })); break; //人 5m外 昼
                    case '9': if (objUnitEdit.TrafficType9Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType9Out5m: false })); break; //人 5m外 昼特定TR
                    case '10': if (objUnitEdit.TrafficType10Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType10Out5m: false })); break; //人 5m外 夜
                    case '12': if (objUnitEdit.TrafficType12Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType12Out5m: false })); break; //人 5m外 夕特定TR
                    case '13': if (objUnitEdit.TrafficType13Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType13Out5m: false })); break; //人 5m外 土曜、休祝日又は行楽地のトップシーズン
                }
            }
            setEdit(false)
            setTrafficData(originData)
            setOriginData([])
            setActionFlag(!actionFlag)
        }

        const editRegist = (target: string, trafficType: string) => {
            const params = setParam(objLongPageParam.planAreaId, trafficData, LOCAL_CONSTANT.VALUE.ADD_PEOPLE)
            if (utilityCtx.showSpinner) {
                utilityCtx.showSpinner();
            }
            (async () => {
                try {
                    // 人通行量 編集フラグ更新
                    if (target === 'in') {
                        switch (trafficType) {
                            case '6': if (objUnitEdit.TrafficType6In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType6In5m: false })); break; //人 5m内 朝
                            case '7': if (objUnitEdit.TrafficType7In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType7In5m: false })); break; //人 5m内 朝特定TR
                            case '8': if (objUnitEdit.TrafficType8In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType8In5m: false })); break; //人 5m内 昼
                            case '9': if (objUnitEdit.TrafficType9In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType9In5m: false })); break; //人 5m内 昼特定TR
                            case '10': if (objUnitEdit.TrafficType10In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType10In5m: false })); break; //人 5m内 夜
                            case '12': if (objUnitEdit.TrafficType12In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType12In5m: false })); break; //人 5m内 夕特定TR
                            case '13': if (objUnitEdit.TrafficType13In5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType13In5m: false })); break; //人 5m内 土曜、休祝日又は行楽地のトップシーズン
                        }
                    } else if (target === 'out') {
                        switch (trafficType) {
                            case '6': if (objUnitEdit.TrafficType6Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType6Out5m: false })); break; //人 5m外 朝
                            case '7': if (objUnitEdit.TrafficType7Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType7Out5m: false })); break; //人 5m外 朝特定TR
                            case '8': if (objUnitEdit.TrafficType8Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType8Out5m: false })); break; //人 5m外 昼
                            case '9': if (objUnitEdit.TrafficType9Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType9Out5m: false })); break; //人 5m外 昼特定TR
                            case '10': if (objUnitEdit.TrafficType10Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType10Out5m: false })); break; //人 5m外 夜
                            case '12': if (objUnitEdit.TrafficType12Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType12Out5m: false })); break; //人 5m外 夕特定TR
                            case '13': if (objUnitEdit.TrafficType13Out5m) dispatch(setUnitEdit({ ...objUnitEdit, TrafficType13Out5m: false })); break; //人 5m外 土曜、休祝日又は行楽地のトップシーズン
                        }
                    }
                    setEdit(false)
                    setActionFlag(!actionFlag)
                    await apiRequest(params);
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                    props.setReload(true)
                } catch (e) {
                    console.log(e);
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                }
            })();
        }

        const textColor = () => {
            return {
                '& .MuiInputBase-input': {
                    textAlign: 'center',
                    color: 'blue',
                },
                "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: "black",
                },
                '& fieldset': {
                    border: 'none',
                },
            }
        }

        const ratio = (key: string) => {
            let ratioValue = 0
            if (key === 'night') {
                ratioValue = Math.round(props.nightRatioValue * 1000) / 10
            } else if (key === 'holidays') {
                ratioValue = Math.round(props.holidaysRatioValue * 1000) / 10
            }

            return ratioValue.toString() + '%'
        }

        const displayRatio = () => {
            let display = 'none'
            if (props.target === LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN) {
                display = 'block'
            } else if (objLocationTraffic5mcut.cut_survey === '1') {
                display = 'block'
            }

            return display
        }

        //必須項目の場合はtrueでアスタリスクを表示
        const requiredStatus: boolean = (props.keyName === LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_6.name ||
            props.keyName === LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_8.name ||
            props.keyName === LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_10.name ||
            props.keyName === LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_13.name
        );


        const checkDisplay = () => {
            let display = 'none'

            if (requiredStatus) {
                display = 'block'
            }

            return display
        }

        return (
            <>
                {
                    props.keyName === LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_10.name &&
                    <Box sx={{ display: displayRatio() }}>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ width: '80%', textAlign: 'center', padding: '10px', border: '2px solid rgba(224, 224, 224, 1)' }}>
                                <FormLabel sx={{ fontSize: '1.4rem' }}>昼間に対する</FormLabel>
                                <FormLabel sx={{ fontSize: '1.4rem', color: 'red' }}>夜間</FormLabel>
                                <FormLabel sx={{ fontSize: '1.4rem' }}>人流比率</FormLabel>
                            </Box>
                            <Box sx={{ width: '20%', textAlign: 'center', padding: '10px', border: '2px solid rgba(224, 224, 224, 1)' }}>
                                <FormLabel sx={{ fontSize: '1.4rem', padding: '0 10px' }}>{ratio('night')}</FormLabel>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ width: '80%', textAlign: 'center', padding: '10px', border: '2px solid rgba(224, 224, 224, 1)' }}>
                                <FormLabel sx={{ fontSize: '1.4rem' }}>平日に対する</FormLabel>
                                <FormLabel sx={{ fontSize: '1.4rem', color: 'red' }}>休日</FormLabel>
                                <FormLabel sx={{ fontSize: '1.4rem' }}>人流比率</FormLabel>
                            </Box>
                            <Box sx={{ width: '20%', textAlign: 'center', padding: '10px', border: '2px solid rgba(224, 224, 224, 1)' }}>
                                <FormLabel sx={{ fontSize: '1.4rem', padding: '0 10px' }}>{ratio('holidays')}</FormLabel>
                            </Box>
                        </Box>
                    </Box>
                }
                {
                    props.keyName === LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_11.name &&
                    <Box sx={{ display: displayRatio() }}>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ width: '80%', textAlign: 'center', padding: '10px', border: '2px solid rgba(224, 224, 224, 0)' }}>
                            </Box>
                            <Box sx={{ width: '20%', textAlign: 'center', padding: '10px', border: '2px solid rgba(224, 224, 224, 0)' }}>
                                <FormLabel sx={{ fontSize: '1.4rem', padding: '0 10px' }}></FormLabel>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex' }}>
                            <Box sx={{ width: '80%', textAlign: 'center', padding: '10px', border: '2px solid rgba(224, 224, 224, 0)' }}>
                            </Box>
                            <Box sx={{ width: '20%', textAlign: 'center', padding: '10px', border: '2px solid rgba(224, 224, 224, 0)' }}>
                                <FormLabel sx={{ fontSize: '1.4rem', padding: '0 10px' }}></FormLabel>
                            </Box>
                        </Box>
                    </Box>
                }
                {
                    <TableContainer sx={{ width: '100%', marginBottom: '30px', border: 'solid 1px rgba(224, 224, 224, 1)', display: tableDisplay }}>
                        <Box sx={{ width: '100%', height: '60px', maxHeight: '60px', display: 'flex', justifyContent: 'space-between' }}>
                            <Typography sx={{ margin: '10px 10px', fontSize: '0.95rem' }}>{props.keyName}</Typography>
                            <Box sx={{ display: checkMenuDisplay() }}>
                                <Button
                                    disabled={setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'trafficPeopleCommentButton')}
                                    onClick={clickCommentOpen}>
                                    <ChatBubbleIcon fontSize="large" />
                                </Button>
                                <Button
                                    sx={{ color: 'black', display: edit ? 'none' : 'block' }}
                                    disabled={setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'trafficPeopleEditButton')}
                                    onClick={() => editStart(props.target, props.trafficType)}
                                ><EditIcon fontSize="large" /></Button>
                                <Button variant="outlined" color="error" size="small"
                                    sx={{ margin: '10px', display: edit ? 'block' : 'none', fontSize: '0.5rem', fontWeight: 'bold' }}
                                    onClick={() => editCancel(props.target, props.trafficType)}
                                >{LOCAL_CONSTANT.LABEL.CANCEL}</Button>
                                <Button variant="contained" color="success" size="small"
                                    sx={{ margin: '10px', display: edit ? 'block' : 'none' }}
                                    onClick={() => editRegist(props.target, props.trafficType)}
                                >{LOCAL_CONSTANT.LABEL.UPDATE}</Button>
                                <Button
                                    sx={{ color: 'black' }}
                                    disabled={setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'trafficPeopleDeleteButton')}
                                    onClick={onClickDelete}
                                ><DeleteIcon fontSize="large" /></Button>
                            </Box>
                        </Box>
                        <Table sx={{ width: '100%' }} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow sx={{ height: '80px', '* p': { fontSize: '0.75rem' } }}>
                                    <TableCell sx={headerStyle(20, 80)} align="center">
                                        <Typography sx={borderStyle(100, 40)}></Typography>
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography sx={borderStyle(50, 40)}>{LOCAL_CONSTANT.LABEL.COUNT_DATE}<span style={{ display: checkDisplay(), color: 'red' }}>＊</span></Typography>
                                            <Typography sx={borderStyle(50, 40)}>{LOCAL_CONSTANT.LABEL.COUNT_TIME}<span style={{ display: checkDisplay(), color: 'red' }}>＊</span></Typography>
                                        </Box>
                                    </TableCell>
                                    {
                                        dataList().map((rowData: any, index) => (
                                            <TableCell sx={headerStyleWithBorder(rowData.w, 80)} align="center" key={rowData.key}>
                                                {
                                                    rowData.key === LOCAL_CONSTANT.LABEL.BICYCLE_LINE_BREAK &&
                                                    <Typography sx={borderStyle(100, rowData.h)}>
                                                        {LOCAL_CONSTANT.LABEL.IN}<br />{LOCAL_CONSTANT.LABEL.BICYCLE_LINE_BREAK}
                                                        <span style={{ display: checkDisplay(), color: 'red' }}>＊</span>
                                                    </Typography>
                                                }
                                                {
                                                    rowData.key === LOCAL_CONSTANT.LABEL.STUDENT &&
                                                    <Typography sx={borderStyle(100, rowData.h)}>{rowData.key}<span style={{ display: checkDisplay(), color: 'red' }}>＊</span></Typography>
                                                }
                                                {
                                                    rowData.key === LOCAL_CONSTANT.LABEL.OVER_SIXTY_FIVE &&
                                                    <Typography sx={borderStyle(100, rowData.h)}>{rowData.key}</Typography>
                                                }
                                                {
                                                    rowData.key === LOCAL_CONSTANT.LABEL.OTHER &&
                                                    <Typography sx={borderStyle(100, rowData.h)}>{rowData.key}</Typography>
                                                }
                                                {
                                                    rowData.key === LOCAL_CONSTANT.LABEL.TOTAL &&
                                                    <Typography sx={borderStyle(100, rowData.h)}>{rowData.key}<span style={{ display: checkDisplay(), color: 'red' }}>＊</span></Typography>
                                                }
                                                {
                                                    rowData.col === 2 &&
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Typography sx={borderStyle(50, 40)}>{LOCAL_CONSTANT.LABEL.MALE}<span style={{ display: checkDisplay(), color: 'red' }}>＊</span></Typography>
                                                        <Typography sx={borderStyle(50, 40)}>{LOCAL_CONSTANT.LABEL.FEMALE}<span style={{ display: checkDisplay(), color: 'red' }}>＊</span></Typography>
                                                    </Box>
                                                }
                                            </TableCell>
                                        ))
                                    }
                                    {
                                        trafficData.length > 0 &&
                                        props.target !== LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN_N_OUT &&
                                        editTrafficList().includes(props.keyName) &&
                                        <TableCell sx={headerStyleWithBorder(2, 80)}></TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {trafficData.map((row: any, dataIndex: number) => (
                                    <TableRow key={dataIndex} sx={{ padding: '0', '* p': { fontSize: '0.75rem' } }}>
                                        <TableCell sx={dataListStyle(20)} align="center">
                                            <Box sx={{ display: 'flex' }}>
                                                {
                                                    edit === false ? (
                                                        <>
                                                            <Typography sx={borderStyle(50, 50)}>
                                                                {dateDisplayByType(row, props.trafficType, 'year')}<br />
                                                                {dateDisplayByType(row, props.trafficType, 'day')}
                                                            </Typography>
                                                            <Typography sx={borderStyle(50, 50)}>{timeDisplay(row, props.trafficType)}</Typography>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Box onClick={() => { openDateModal(row) }}
                                                                sx={borderStyle(50, 50)}
                                                            >
                                                                <Typography sx={{ color: 'blue' }}>
                                                                    {dateDisplayByType(row, props.trafficType, 'year')}<br />
                                                                    {dateDisplayByType(row, props.trafficType, 'day')}
                                                                </Typography>
                                                            </Box>
                                                            <Box onClick={() => { openCountDateModal(row) }}
                                                                sx={borderStyle(50, 50)}
                                                            >
                                                                <Typography sx={{ color: 'blue' }}>{timeDisplay(row, props.trafficType)}</Typography>
                                                            </Box>
                                                        </>

                                                    )
                                                }
                                            </Box>
                                        </TableCell>
                                        {
                                            dataList().map((rowData: any) => (
                                                <TableCell sx={dataStyle(rowData.w)} align="center" key={rowData.key}>
                                                    {
                                                        rowData.col === 1 &&
                                                        <Box sx={borderStyle(100, 50)}>
                                                            <TextField sx={textColor()} disabled={!edit}
                                                                value={peopleAddition(row[getTargetLabel(rowData.inputKey)], row, rowData.inputKey, props.target, props.trafficType, props.nightRatio, props.holidaysRatio)}
                                                                onChange={(e) => changeHandler(e, row, getTargetLabel(rowData.inputKey))}
                                                            ></TextField>
                                                        </Box>
                                                    }
                                                    {
                                                        rowData.col === 2 &&
                                                        <Box sx={{ display: 'flex' }}>
                                                            <Box sx={borderStyle(50, 50)}>
                                                                <TextField sx={textColor()} disabled={!edit}
                                                                    value={peopleAddition(row[getTargetLabel(rowData.inputKey_male)], row, rowData.inputKey_male, props.target, props.trafficType, props.nightRatio, props.holidaysRatio)}
                                                                    onChange={(e) => changeHandler(e, row, getTargetLabel(rowData.inputKey_male))}
                                                                ></TextField></Box>
                                                            <Box sx={borderStyle(50, 50)}>
                                                                <TextField sx={textColor()} disabled={!edit}
                                                                    value={peopleAddition(row[getTargetLabel(rowData.inputKey_female)], row, rowData.inputKey_female, props.target, props.trafficType, props.nightRatio, props.holidaysRatio)}
                                                                    onChange={(e) => changeHandler(e, row, getTargetLabel(rowData.inputKey_female))}
                                                                ></TextField></Box>
                                                        </Box>
                                                    }
                                                </TableCell>
                                            ))
                                        }
                                        {
                                            trafficData.length > 0 &&
                                            props.target !== LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN_N_OUT &&
                                            editTrafficList().includes(props.keyName) &&
                                            <TableCell sx={{
                                                width: '1%', minWidth: '1%', maxWidth: '1%',
                                                padding: '0',
                                                borderLeft: "solid 2px rgba(100, 100, 100, 1)",
                                                "& .MuiSvgIcon-fontSizeSmall": {
                                                    cursor: 'pointer',
                                                    margin: '8px 4px',
                                                },
                                            }}>
                                                <Box sx={{
                                                    width: '100%', minWidth: '100%', maxWidth: '100%',
                                                    padding: '0',
                                                    display: 'flex',
                                                    justifyContent: 'space-around',
                                                }}>
                                                    <Button sx={{
                                                        minWidth: '0',
                                                        color: 'black'
                                                    }}
                                                        disabled={setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'trafficCarEditButton')}
                                                    >
                                                        <AutorenewIcon onClick={() => { changePeople(row) }} fontSize="small" />
                                                    </Button>
                                                    <Button sx={{
                                                        minWidth: '0',
                                                        color: 'black'
                                                    }}
                                                        disabled={setPermissionFunc(props.userType, objLocationBase.task_id, LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME, 'trafficCarEditButton')}
                                                    >
                                                        <DeleteIcon onClick={() => { deletePeople(row) }} fontSize="small" />
                                                    </Button>
                                                </Box>
                                            </TableCell>
                                        }
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell sx={dataListStyle(20)} align="center">
                                        <Box sx={{ display: 'flex' }}>
                                            <Typography sx={borderStyle(50, 50)}></Typography>
                                            <Typography sx={borderStyleColor(50, 50)}>{LOCAL_CONSTANT.LABEL.AVG}</Typography>
                                        </Box>
                                    </TableCell>
                                    {
                                        dataList().map((rowData: any) => (
                                            <TableCell sx={dataStyle(rowData.w)} align="center" key={rowData.key}>
                                                {
                                                    rowData.col === 1 &&
                                                    <Typography sx={borderStyle(100, 50)}>{peopleAvg(trafficData, rowData.inputKey, props.target, props.trafficType, props.nightRatio, props.holidaysRatio)}</Typography>
                                                }
                                                {
                                                    rowData.col === 2 &&
                                                    <Box sx={{ display: 'flex' }}>
                                                        <Typography sx={borderStyle(50, 50)}>{peopleAvg(trafficData, rowData.inputKey_male, props.target, props.trafficType, props.nightRatio, props.holidaysRatio)}</Typography>
                                                        <Typography sx={borderStyle(50, 50)}>{peopleAvg(trafficData, rowData.inputKey_female, props.target, props.trafficType, props.nightRatio, props.holidaysRatio)}</Typography>
                                                    </Box>
                                                }
                                            </TableCell>
                                        ))
                                    }
                                    {
                                        trafficData.length > 0 &&
                                        props.target !== LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN_N_OUT &&
                                        editTrafficList().includes(props.keyName) &&
                                        <TableCell sx={dataStyle(2)}></TableCell>
                                    }
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
                <DateModal props={{
                    open: openDate,
                    handleModal: setOpenDate,
                    data: targetDate,
                    trafficType: props.trafficType,
                    setDate: setDate,
                }} />
                <CountDate props={{
                    open: openCountDate,
                    handleModal: setOpenCountDate,
                    data: targetCountDate,
                    trafficType: props.trafficType,
                    setCountDate: setCountDate,
                }} />
                <Comment props={{
                    open: commentOpen,
                    params: {
                        table_name: "location_traffic",
                        column_name: text,
                        comment: "",
                    },
                    handleModal: setCommentOpen,
                    handleSaveComment: handleSaveComment
                }} />
                <ConfirmDialog props={{
                    open: confirmDialogOpen,
                    handleModal: setConfirmDialogOpen,
                    onCancel: deleteCancel,
                    onExecution: deleteResult,
                    mode: "deleteConfirm",
                    body: setBody(),
                    confirmCancel: LOCAL_CONSTANT.LABEL.CANCEL,
                    confirmOk: LOCAL_CONSTANT.LABEL.DELETE
                }}
                />
                <MessageDialog props={{
                    open: messageDialogOpen,
                    handleModal: closeMessageDialog,
                    body: message,
                    hasButton: hasButton,
                    buttonBody: LOCAL_CONSTANT.LABEL.CLOSE
                }}
                />
                <ChangeTrafficVolume props={{
                    open: changeOpen,
                    handleModal: setChangeOpen,
                    target: props.target,
                    setReload: props.setReload,
                    targetTraffic: targetTraffic
                }} />
            </>
        );

    }


    export default TrafficVolumePeople;
