import { planAreaSearchById, getConflictStores, existingSejStoreSearchByDistance, getExistingSejConflictStores, getLongPageData } from "../../../data/DataManage";
import { getRouteDistance } from "../../../utility/turf";
import { LOCAL_CONSTANT } from "../../Const";
import { zIndexOrder } from "../Interface";
import { CVS_MASTER_DATA, SM_DRUG_MASTER_DATA } from "../../ResearchReport/Conflict";
import { brandPath } from "../viewData/viewDataFunction";

export const BUSINESS_TYPE_MINI_SM = "ミニＳＭ";
export const BUSINESS_TYPE_SM = "ＳＭ";
export const BUSINESS_TYPE_GMS = "ＧＭＳ";
export const BUSINESS_TYPE_MINI_DS = "ミニＤＳ";
export const BUSINESS_TYPE_DS = "ＤＳ";
export const BUSINESS_TYPE_GDS = "大規模ＤＳ";
export const BASIC_CONDUCTOR = "基導";
export const BASIC = "基";
export const CONDUCTOR = "導";
export const NOT_APPLICABLE = "Ｘ";
export const BASIC_CONDUCTOR_VALUE = "0"; // 基導
export const BASIC_VALUE = "1"; // 基礎
export const CONDUCTOR_VALUE = "2"; // 導線
export const NOT_APPLICABLE_VALUE = "3"; // 対象外

export const callApi = async (target: string, params: any, user: any) => {
    const param: any = [];
    if (target === "planArea") {
        const jsonUserData = JSON.parse(user.userData);
        let manageCitiesFlg = true;
        if (jsonUserData.administrator_flag === "1" || jsonUserData.manage_cities?.includes("ALL")) {
            manageCitiesFlg = false;
        }
        const manage_steps = ["0", "8", "99"];
        let stepCnt = jsonUserData.manage_steps.filter((val: any) => manage_steps.includes(val) === false);
        let managePlansOutsideCities = jsonUserData.manage_plans_outside_cities;
        if (!managePlansOutsideCities.includes("")) {
            managePlansOutsideCities.push("");
        }

        return await planAreaSearchById({
            mode: "planAreaSearchById",
            id: params.id,
            manageCitiesFlg: manageCitiesFlg,
            manageCities: jsonUserData.manage_cities.join(","),
            manageStep: stepCnt.join(","),
            managePlansOutsideCities: managePlansOutsideCities.join(","),
        });
    } else if (target === "conflictStore") {
        return await getConflictStores({
            mode: "getConflictStores",
            longitude: params.planArea.centerlon,
            latitude: params.planArea.centerlat,
            distance: params.distance,
            type: "",
            hasClosed: true,
        });
    } else if (target === "existingSejStore") {
        return await existingSejStoreSearchByDistance({
            mode: "existingSejStoreSearchByDistance",
            longitude: params.planArea.centerlon,
            latitude: params.planArea.centerlat,
            distance: params.distance,
        });
    } else if (target === "getExistingSejConflictStores") {
        return await getExistingSejConflictStores({
            mode: "getExistingSejConflictStores",
            longitude: params.centerlon,
            latitude: params.centerlat,
            distance: params.distance,
            propSiteNo: params.plan_area_id,
            excelSequence: params.excelSequence,
            type: params.type,
            hasClosed: true,
        });
    } else if (target === "getLocationLinkByUsePlanAreaId") {
        param.push({
            mode: "getLocationLinkByUsePlanAreaId",
            use_plan_area_id: params.plan_area_id,
        });
        return await getLongPageData(param);
    } else if (target === "getExistingSejStore") {
        param.push({
            mode: "getExistingSejStore",
            store_code: params.store_code,
        });
        return await getLongPageData(param);
    } else if (target === "getConflictPlanArea") {
        param.push({
            mode: "getConflictPlanArea",
            longitude: params.centerlon,
            latitude: params.centerlat,
            distance: params.distance,
            propSiteNo: params.plan_area_id,
            excelSequence: params.excelSequence,
        });
        return await getLongPageData(param);
    }
};

// ラベル追加
export const setSelectStoreLabel = (map: google.maps.Map, targetStore: any) => {
    let x = 0;
    let y = 0;
    let labelText = "";
    if (targetStore.label === BASIC_CONDUCTOR_VALUE) {
        labelText = BASIC_CONDUCTOR;
    } else if (targetStore.label === BASIC_VALUE) {
        labelText = BASIC;
    } else if (targetStore.label === CONDUCTOR_VALUE) {
        labelText = CONDUCTOR;
    } else if (targetStore.label === NOT_APPLICABLE_VALUE) {
        labelText = NOT_APPLICABLE;
    }

    if (labelText) {
        if (targetStore.editStore.bland_code === "1") {
            x = 14;
            y = 12;
        } else {
            x = 16;
            y = 16;
            if (brandPath(targetStore.marker.icon.url)) {
                x = 10;
                y = 10;
            }
        }
        if (labelText === BASIC_CONDUCTOR) {
            x += 4;
        }

        const fontWeight = [NOT_APPLICABLE].includes(labelText) ? "bold" : "normal";

        var marker = new google.maps.Marker({
            position: {
                lat: targetStore.storeData.centerlat,
                lng: targetStore.storeData.centerlon,
            },
            icon: {
                url: process.env.PUBLIC_URL + "/image/icons/sej_normal.svg",
                scaledSize: new google.maps.Size(0, 0),
                labelOrigin: new google.maps.Point(x, y),
            },
            label: {
                text: labelText,
                color: "#000000",
                fontSize: "16px",
                fontWeight: fontWeight,
            },
            zIndex: zIndexOrder.conflictMarker,
        });
        marker.setMap(map);

        return marker;
    }

    return "";
};

// ラベル追加
export const setMarketCutMarker = (map: google.maps.Map, targetStore: any) => {
    let x = 0;
    let y = 0;
    let marketCutLabel = "";
    let labelText = "";
    if (targetStore.label === BASIC_CONDUCTOR_VALUE) {
        labelText = BASIC_CONDUCTOR;
    } else if (targetStore.label === BASIC_VALUE) {
        labelText = BASIC;
    } else if (targetStore.label === CONDUCTOR_VALUE) {
        labelText = CONDUCTOR;
    } else if (targetStore.label === NOT_APPLICABLE_VALUE) {
        labelText = NOT_APPLICABLE;
    }

    if (labelText) {
        if (targetStore.editStore.market_cut === "1") {
            marketCutLabel = " C";
            if (targetStore.editStore.bland_code === "1") {
                x = 28;
                y = 11;
            } else {
                x = 30;
                y = 15;
                if (brandPath(targetStore.marker.icon.url)) {
                    x = 22;
                    y = 9;
                }
            }
            if (labelText === BASIC_CONDUCTOR) {
                x += 12;
            }
            var marker = new google.maps.Marker({
                position: {
                    lat: targetStore.storeData.centerlat,
                    lng: targetStore.storeData.centerlon,
                },
                icon: {
                    url: process.env.PUBLIC_URL + "/image/icons/sej_normal.svg",
                    scaledSize: new google.maps.Size(0, 0),
                    labelOrigin: new google.maps.Point(x, y),
                },
                label: {
                    text: marketCutLabel,
                    color: "#FF0000",
                    fontSize: "16px",
                },
                zIndex: zIndexOrder.conflictMarker,
            });
            marker.setMap(map);

            return marker;
        }
    }

    return "";
};

// ラベル追加
export const setSelectStoreNumber = (map: google.maps.Map, targetStore: any, numberText: string, color: string) => {
    var marker = new google.maps.Marker({
        position: {
            lat: targetStore.data.centerlat,
            lng: targetStore.data.centerlon,
        },
        icon: {
            url: process.env.PUBLIC_URL + "/image/icons/sej_normal.svg",
            scaledSize: new google.maps.Size(0, 0),
            labelOrigin: brandPath(targetStore.marker.icon.url) ? new google.maps.Point(0, -16) : new google.maps.Point(0, -24),
        },
        label: {
            text: numberText,
            color: color,
            fontWeight: "bold",
            fontSize: "24px",
        },
    });
    marker.setMap(map);

    return marker;
};

// 競合調査選択リストに含まれているかチェック
export const hasSelectStoreList = (selectStoreList: any, targetStoreData: any, target: string) => {
    if (selectStoreList.length === 0) {
        return false;
    }
    let hasStore = false;
    if (target === "1") {
        selectStoreList.map((row: any) => {
            if (row.key === targetStoreData.uuid) {
                hasStore = true;
            }
        });
    } else {
        selectStoreList.map((row: any) => {
            if (row.key === targetStoreData.store_code) {
                hasStore = true;
            }
        });
    }

    return hasStore;
};

export const setDistance = (map: google.maps.Map, planAreaList: any, target: any, setDirections: any, setRoadDistance: any, setDirectionsData: any) => {
    getRouteDistance(new google.maps.LatLng(planAreaList[0].centerlat, planAreaList[0].centerlon), new google.maps.LatLng(target.centerlat, target.centerlon), google.maps.TravelMode.DRIVING).then(
        (data: any) => {
            const directionsDisplay = new google.maps.DirectionsRenderer({
                draggable: true,
                preserveViewport: true,
            });
            directionsDisplay.setMap(map);
            directionsDisplay.setDirections(data);

            setDirections(directionsDisplay);

            setRoadDistance(target.roadDistance);
            target.roadDistance = data.routes[0].legs[0].distance.value;
            directionsDisplay.addListener("directions_changed", () => {
                const changeRoute = directionsDisplay.getDirections()?.routes[0];
                target.roadDistance = changeRoute?.legs[0].distance?.value;
                setDirectionsData({
                    uuid: target.uuid,
                    distance: changeRoute?.legs[0].distance?.value,
                });
            });
        }
    );
};

/**
 * ルートパスのpolyline取得
 * @param map
 * @param route
 * @param color
 */
export const setRoutePath = (route: any, color: string) => {
    var polyline = new google.maps.Polyline({
        path: route?.overview_path,
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
    });

    return polyline;
};

// すべてのクリックの制御
export const setClickable = (lsit: any, clickable: boolean) => {
    lsit?.map((row: any) => {
        row.marker.setOptions({
            clickable: clickable,
        });
    });
};

export const replaceToBlank = (targetString: string) => {
    return targetString !== null ? targetString : "";
};

export const hasConflictSubject = (selectStore: any, storeData: any, targetRadius: string, type: string) => {
    let basics = false;
    let conductorWire = false;
    let subject = "";

    if (type === CVS_MASTER_DATA) {
        // ★基礎競合判定
        if (selectStore.type === "0") {
            // ■セブンイレブンの場合、以下二つの条件を両方満たす
            // １．直線距離が500m以下（≦500）
            // ２．推定日販が180千円以上（≧180）
            if (storeData.direct_distance !== "" && Number(storeData.direct_distance) <= 500 && Number(storeData.estimated_daily_sales) >= 180) {
                basics = true;
            }
        } else {
            // ■セブンイレブン以外の場合、以下二つの条件を両方満たす
            // １．直線距離が350以下（≦350）
            // ２．推定日販が180千円以上（≧180）
            if (storeData.direct_distance !== "" && Number(storeData.direct_distance) <= 350 && Number(storeData.estimated_daily_sales) >= 180) {
                basics = true;
            }
        }
        // ■導線判定
        // ★共通
        // And 24H営業
        // または、24H以外かつ閉店時間20:01～12:00
        // And 駐車場有り
        // And 道なり距離<=距離基準※
        // 右左折計=0or1

        // ※距離基準
        // 商圏密度：1～2　＝　3000
        // 商圏密度：3　　＝　2000
        // 商圏密度：4以上　＝　1000
        if (
            (storeData.business_hours === "1" || (storeData.business_hours !== "1" && (Number(storeData.closing_time) >= 2001 || Number(storeData.closing_time) <= 1200))) &&
            storeData.handle_category_parking === "1" &&
            storeData.road_distance !== "" &&
            Number(storeData.road_distance) <= Number(targetRadius) * 1000 &&
            storeData.count_left_right_turns !== "" &&
            Number(storeData.count_left_right_turns) < 2
        ) {
            conductorWire = true;
        }
    } else if (type === SM_DRUG_MASTER_DATA) {
        const resultBusinessType = conflictBusinessType(storeData, storeData["business_type"]);
        // ★基礎競合判定
        // 以下のいずれかを満たす場合、基礎競合
        if (["1", "4"].includes(resultBusinessType) && storeData.direct_distance !== "" && Number(storeData.direct_distance) <= 350) {
            // ・業態が「ミニSM」or「ミニDS」　かつ　直線距離≦350
            basics = true;
        } else if (["2", "5"].includes(resultBusinessType) && storeData.direct_distance !== "" && Number(storeData.direct_distance) <= 500) {
            // ・業態が「SM」or「DS」　かつ　直線距離≦500
            basics = true;
        } else if (["3", "6"].includes(resultBusinessType) && storeData.direct_distance !== "" && Number(storeData.direct_distance) <= 800) {
            // ・業態が「GMS」or「大規模DS」　かつ　直線距離≦800
            basics = true;
        }

        // ★導線競合判定
        if (["1", "2", "3"].includes(resultBusinessType)) {
            // ■SM系の場合（業態が「ミニSM」「SM」「GMS」のとき）
            // 以下の条件をすべて満たす場合
            // ・24時間
            // または、24H以外かつ閉店時間20:01～12:00
            // ・駐車場あり
            // ・道なり距離が距離基準※以下
            // ・右左折計が2未満
            // ※距離基準
            // 商圏密度が～2＝3000
            // 商圏密度が3＝2000
            // 商圏密度が4～＝1000
            if (
                (storeData.business_hours === "1" || (storeData.business_hours !== "1" && (Number(storeData.closing_time) >= 2001 || Number(storeData.closing_time) <= 1200))) &&
                storeData.handle_category_parking === "1" &&
                storeData.road_distance !== "" &&
                Number(storeData.road_distance) < Number(targetRadius) * 1000 &&
                storeData.count_left_right_turns !== "" &&
                Number(storeData.count_left_right_turns) < 2
            ) {
                conductorWire = true;
            }
        } else if (["4", "5", "6"].includes(resultBusinessType)) {
            // ■DS系の場合（業態が「ミニDS」「DS」「大規模DS」のとき）
            // 以下の条件をすべて満たす場合
            // ・24時間
            // または、24H以外かつ閉店時間20:01～12:00
            // ・駐車場あり
            // ・道なり距離が1000以下
            // ・右左折計が2未満
            if (
                (storeData.business_hours === "1" || (storeData.business_hours !== "1" && (Number(storeData.closing_time) >= 2001 || Number(storeData.closing_time) <= 1200))) &&
                storeData.handle_category_parking === "1" &&
                storeData.road_distance !== "" &&
                Number(storeData.road_distance) < 1000 &&
                storeData.count_left_right_turns !== "" &&
                Number(storeData.count_left_right_turns) < 2
            ) {
                conductorWire = true;
            }
        }
        // ★最終判定
        // 基礎のみ：基礎競合
        // 導線のみ：導線競合
        // 基礎と導線：基礎＋導線
        // 上記以外：対象外
    }
    subject = NOT_APPLICABLE_VALUE;
    if (basics && conductorWire) {
        subject = BASIC_CONDUCTOR_VALUE;
    } else if (basics) {
        subject = BASIC_VALUE;
    } else if (conductorWire) {
        subject = CONDUCTOR_VALUE;
    }

    return subject;
};

export const checkDisabled = (data: any, key: string) => {
    let disabled = false;
    switch (key) {
        case "road_distance":
        case "business_hours":
        case "closing_time":
        case "count_left_right_turns":
            disabled = true;
            if (data.conductor_not_included === "1") {
                disabled = false;
            } else {
                if (data.conductor_not_included === "0") {
                    if (data.handle_category_parking === "1") {
                        disabled = false;
                    }
                }
            }
            break;
        case "plan_area_shared_road":
        case "plan_area_direction":
        case "conflict_shared_road":
        case "conflict_median_strip":
        case "count_straight":
            disabled = true;
            if (data.conductor_not_included === "1") {
                disabled = false;
            } else {
                if (data.conductor_not_included === "0") {
                    if ([BASIC_CONDUCTOR_VALUE, CONDUCTOR_VALUE].includes(data.location_conflict_type)) {
                        disabled = false;
                    }
                }
            }

            break;
    }

    return disabled;
};

export const checkListDisabled = (data: any, key: string) => {
    let disabled = false;
    switch (key) {
        case "store_frontage":
            disabled = true;
            if (data.handle_category_parking === "0") {
                disabled = false;
            }
            break;
        case "parking":
            disabled = true;
            if (data.handle_category_parking === "1") {
                disabled = false;
            }
            break;
        case "front":
            disabled = true;
            if (data.handle_category_parking === "1") {
                disabled = false;
            }
            break;
        case "side":
            disabled = true;
            if (data.handle_category_parking === "1" && data.side_road_type !== "2") {
                disabled = false;
            }
            break;
        case "plan_area_shared_road":
        case "plan_area_direction":
        case "conflict_shared_road":
        case "conflict_median_strip":
        case "count_left_right_turns":
        case "count_straight":
        case "road_distance":
        case "business_hours":
        case "closing_time":
            disabled = true;
            if (data.handle_category_parking === "1") {
                disabled = false;
            }
            break;
        case "closing":
            disabled = true;
            if (data.business_hours === "0") {
                disabled = false;
            }
            break;
        case "bland_name":
        case "side_road_type":
        case "direct_distance":
            disabled = true;
            break;
    }

    return disabled;
};

export const subjectStr = (conflictSubject: string) => {
    return conflictSubject === LOCAL_CONSTANT.LABEL.NOT_SUBJECT ? LOCAL_CONSTANT.LABEL.NOT_SUBJECT : LOCAL_CONSTANT.LABEL.SUBJECT;
};

export const conflictSubjectColor = (conflictSubject: string) => {
    return conflictSubject === "" ? "##ccc" : conflictSubject === NOT_APPLICABLE_VALUE ? "#ccc" : "#0a0";
};

export const setSelecter = (codeMaster: any, key: string) => {
    if (codeMaster) {
        if (codeMaster[key]) {
            return codeMaster[key];
        }
    }

    return [];
};

export const setSelecterDisplay = (codeMaster: any, key: string, code_value: string) => {
    let code_display = "";
    if (codeMaster) {
        if (codeMaster[key] && code_value !== "" && code_value !== undefined) {
            codeMaster[key].filter((row: any) => {
                if (row.code_value === code_value.toString()) {
                    code_display = row.code_display;
                }
            });
        }
    }
    return code_display;
};

export const setSelecterValue = (codeMaster: any, key: string, code_display: string) => {
    let code_value = "";
    if (codeMaster) {
        if (codeMaster[key] && code_display !== "" && code_display !== undefined) {
            codeMaster[key].filter((row: any) => {
                if (row.code_display === code_display.toString()) {
                    code_value = row.code_value;
                }
            });
        }
    }
    return code_value;
};

export const roundDistance = (roadDistance: number) => {
    return Math.round(roadDistance).toString();
};

export const drawCircle = (centerlat: number, centerlon: number) => {
    const circleList = [
        { radius: 350, color: "#362CDF" },
        { radius: 500, color: "#7EF4F9" },
        { radius: 800, color: "#00FC06" },
        { radius: 1000, color: "#FFFF0A" },
        { radius: 2000, color: "#7BE0E5" },
        { radius: 3000, color: "#5ADA5E" },
        { radius: 6000, color: "#362CDF" },
    ];
    const tmpCircle: any = [];
    circleList.map((row: any) => {
        const circle = new google.maps.Circle({
            strokeColor: row.color,
            strokeOpacity: 1,
            strokeWeight: 2,
            fillColor: "#0000FF",
            fillOpacity: 0,
            center: {
                lat: centerlat,
                lng: centerlon,
            },
            radius: row.radius,
        });
        circle.setOptions({
            zIndex: zIndexOrder.circles,
        });

        tmpCircle.push(circle);
    });

    return tmpCircle;
};

// 商圏カット
export const upsertLocationConflictMarketCut = (object: any, plan_area_id: any, branch: any) => {
    const params = {
        mode: "regist",
        endPoint: "/location/Conflict/v1/regist-locationConflictMarketCut",
        query: {
            plan_area_id: plan_area_id,
            branch: branch,
            create_date: object.create_date,
            update_date: object.update_date,
            delete_flag: object.delete_flag,
            households: object.households,
            employees: object.employees,
            excel_path: object.excel_path,
            market_cut_img_path: object.market_cut_img_path,
            market_cut_basis_img_path: object.market_cut_basis_img_path,
            approval_confirm_flag: object.approval_confirm_flag,
            approval_confirm_user: object.approval_confirm_user,
            approval_confirm_department: object.approval_confirm_department,
            approval_confirm_date: object.approval_confirm_date,
            approval_confirm_stamp: object.approval_confirm_stamp,
            approval_research_mgr_flag: object.approval_research_mgr_flag,
            approval_research_mgr_user: object.approval_research_mgr_user,
            approval_research_mgr_department: object.approval_research_mgr_department,
            approval_research_mgr_date: object.approval_research_mgr_date,
            approval_research_mgr_stamp: object.approval_research_mgr_stamp,
            approval_research_gm_flag: object.approval_research_gm_flag,
            approval_research_gm_user: object.approval_research_gm_user,
            approval_research_gm_department: object.approval_research_gm_department,
            approval_research_gm_date: object.approval_research_gm_date,
            approval_research_gm_stamp: object.approval_research_gm_stamp,
            approval_mgr_flag: object.approval_mgr_flag,
            approval_mgr_user: object.approval_mgr_user,
            approval_mgr_department: object.approval_mgr_department,
            approval_mgr_date: object.approval_mgr_date,
            approval_mgr_stamp: object.approval_mgr_stamp,
            approval_gm_flag: object.approval_gm_flag,
            approval_gm_user: object.approval_gm_user,
            approval_gm_department: object.approval_gm_department,
            approval_gm_date: object.approval_gm_date,
            approval_gm_stamp: object.approval_gm_stamp,
            cut_market: object.cut_market,
        },
    };

    return params;
};

export const closingTime = (closingTime: string) => {
    let hours = "00";
    let minutes = "00";
    if (replaceToBlank(closingTime).length === 4) {
        hours = closingTime.slice(0, 2);
        minutes = closingTime.slice(2, 4);
        let roundMinutes = Math.round(Number(Number(minutes) / 15)).toString();
        if (roundMinutes === "4") {
            minutes = "00";
        } else {
            minutes = (Number(roundMinutes) * 15).toString().padStart(2, "0");
        }
    }

    return { hours: hours, minutes: minutes };
};

export const displayClosingTime = (time: string, hasDisplay: boolean) => {
    const resClosingTime = closingTime(time);
    return hasDisplay ? Number(resClosingTime.hours).toString() + LOCAL_CONSTANT.LABEL.COLON + resClosingTime.minutes.toString() : resClosingTime.hours.toString() + resClosingTime.minutes.toString();
};

export const validationCheck = (data: any) => {
    const response: any = [];
    data.map((row: any) => {
        // 直線距離
        if (!Number.isInteger(Number(row.direct_distance))) {
            response.push(LOCAL_CONSTANT.CONFIRM_MESSAGE.DIRECT_DISTANCE_ISINTEGER + "\n");
        }
        // 道なり距離
        if (!Number.isInteger(Number(row.road_distance))) {
            response.push(LOCAL_CONSTANT.CONFIRM_MESSAGE.ROAD_DISTANCE_ISINTEGER + "\n");
        }
    });

    return Array.from(new Set(response));
};

export const conflictBusinessType = (editData: any, business_type: string) => {
    let conflict_business_type = "";
    if (Number(editData["sales_floor_area_size"]) < 500) {
        if (business_type === "0") {
            conflict_business_type = "1";
        } else if (business_type === "1") {
            conflict_business_type = "4";
        }
    } else if (Number(editData["sales_floor_area_size"]) < 6600) {
        if (business_type === "0") {
            conflict_business_type = "2";
        } else if (business_type === "1") {
            conflict_business_type = "5";
        }
    } else if (Number(editData["sales_floor_area_size"]) >= 6600) {
        if (business_type === "0") {
            conflict_business_type = "3";
        } else if (business_type === "1") {
            conflict_business_type = "6";
        }
    }

    return conflict_business_type;
};

export const business_type_value = (selectData: any) => {
    let business_type = "";
    if (selectData.id.startsWith("SM")) {
        business_type = "0";
    } else if (selectData.id.startsWith("DS")) {
        business_type = "1";
    }

    return business_type;
};

export const conflict_business_type_trans_business_type = (conflict_business_type: any) => {
    let business_type = "";
    if (["1", "2", "3"].includes(conflict_business_type)) {
        business_type = "0";
    } else if (["4", "5", "6"].includes(conflict_business_type)) {
        business_type = "1";
    }

    return business_type;
};

export const sortLocationConflictData = (res: any, key: string) => {
    return res.sort((a: any, b: any) => {
        return a[key] - b[key];
    });
};
