import { zIndexOrder } from "../Interface";
import { getCvsBrandCodeValue } from "../../../utility/codeMaster";

export const putMarker = (data: any, target: string) => {
    // marker
    var marker = new google.maps.Marker({
        position: {
            lat: data.centerlat,
            lng: data.centerlon,
        },
    });

    let iconUrl = "";
    let x = 0;
    let y = 0;
    let zIndex = 0;
    if (target === "sej") {
        iconUrl = process.env.PUBLIC_URL + "/image/icons/sej_normal.png";
        x = 10;
        y = 10;
        zIndex = zIndexOrder.existingSejStoreIcon;
    } else if (target === "sejSvg") {
        iconUrl = process.env.PUBLIC_URL + "/image/icons/sej_normal.svg";
        x = 20;
        y = 40;
        zIndex = zIndexOrder.existingSejSvgStoreIcon;
    } else if (target === "conflict") {
        let date = new Date();
        let nowY4M = date.getFullYear() + (date.getMonth() + 1).toString().padStart(2, "0");
        let nowY2M = date.getFullYear().toString().substring(2, 4) + (date.getMonth() + 1).toString().padStart(2, "0");
        iconUrl = setInnerConflictIconPath(data.bland_id, data.handle_category_tobacco, data.open_date === nowY4M || data.open_date === nowY2M ? "1" : "0");
        x = 10;
        y = 14;
        if (brandPath(iconUrl)) {
            x = 5;
            y = 2;
        }
        zIndex = zIndexOrder.conflictStoreIcon;
        if (data.bland_id.length > 3) {
            zIndex = zIndexOrder.closedConflictStoreIcon;
        }
    } else if (target === "planArea") {
        x = 15;
        y = 15;
        if (Number(data.step) === 0) {
            // STEP0：黒
            iconUrl = process.env.PUBLIC_URL + "/image/icons/double_circle_black.svg";
        } else if (Number(data.step) === 7) {
            // STEP7：透けてるSEJアイコン
            iconUrl = process.env.PUBLIC_URL + "/image/icons/sej_cleanness.png";
        } else if (data.zone_id) {
            if (!data.zone_is_partial_step) {
                // 同じZONE内にSTEP4以上の候補地が存在しない（is_partial_step=false）
                iconUrl = process.env.PUBLIC_URL + "/image/icons/double_circle_blue.svg";
            } else if (Number(data.step) >= 4) {
                // 同じZONE内にSTEP4以上の候補地が存在する（is_partial_step=true）
                // STEP4以上：赤
                iconUrl = process.env.PUBLIC_URL + "/image/icons/double_circle.svg";
            } else {
                // 同じZONE内にSTEP4以上の候補地が存在する（is_partial_step=true）
                // STEP3以下：グレー
                iconUrl = process.env.PUBLIC_URL + "/image/icons/double_circle_gray.svg";
            }
        } else {
            // ZONEに入っていない（zone_idが空）
            // →STEP1～6：青
            iconUrl = process.env.PUBLIC_URL + "/image/icons/double_circle_blue.svg";
        }
    }

    marker.setOptions({
        icon: {
            url: iconUrl,
            anchor: new google.maps.Point(x, y),
        },
        zIndex: zIndex,
    });

    return marker;
};

export const brandPath = (url: string) => {
    let hasPath = false;
    if (
        url.indexOf("conflict_tobacco_new.svg") !== -1 ||
        url.indexOf("conflict_closed_tobacco.svg") !== -1 ||
        url.indexOf("conflict_tobacco.svg") !== -1 ||
        url.indexOf("conflict_normal_new.svg") !== -1 ||
        url.indexOf("conflict_closed.svg") !== -1 ||
        url.indexOf("conflict_normal.svg") !== -1
    ) {
        hasPath = true;
    }

    return hasPath;
};

export const clearMarker = (list: any) => {
    list.forEach((data: any) => {
        data.marker.setMap(null);
    });
};

/**
 * 競合店アイコンの設定
 * @param bland_id
 * @param isTobacco
 * @param isNewShop
 * @returns
 */
const setInnerConflictIconPath = (bland_id: string, isTobacco: string, isNewShop: string) => {
    if (isTobacco === "1" && isNewShop === "1") {
        return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_tobacco_new.svg";
    } else if (isTobacco === "1" && String(bland_id).length === 4) {
        return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_closed_tobacco.svg";
    } else if (isTobacco === "1") {
        return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_tobacco.svg";
    } else if (isNewShop === "1") {
        return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_normal_new.svg";
    } else if (String(bland_id).length === 4) {
        return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_closed.svg";
    } else {
        return process.env.PUBLIC_URL + getCvsBrandCodeValue(Number(bland_id)).iconPathBase + "_normal.svg";
    }
};
