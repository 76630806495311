import React from "react";
import dayjs from "dayjs";

import {
    Dialog,
    DialogContent,
    DialogContentText,
    Box,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useUtilityContext } from '../../../utility-provider';
import { setApprovalStatus } from "../../../features/approval5mcutSlice";
import { apiRequest, postPython } from "../../../api/ApiCall";
import { useRouteLoaderData } from "react-router-dom";
import MessageDialog from '../../components/MessageDialog';
import { LOCAL_CONSTANT } from "../../Const";

export interface approval5mcutModalProps {
    props: {
        open: boolean,
        handleModal: any,
        planAreaId: string | null,
        branch: string | null,
        locationTraffic5mcut: any,
        locationConflictMarketCut: any,
        userData: any,
    }
}


const Approval5mcutModal: React.FC<approval5mcutModalProps> = ({ props }) => {
    const dispatch = useAppDispatch();
    const utilityCtx = useUtilityContext();

    const [open, setOpen] = React.useState(false);
    const [hasDisplay, setHasDisplay] = React.useState('none');

    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
    const objApproval5mcut = useAppSelector((state) => state.approval5mcut.approvalStatus);
    const [message, setMessage] = React.useState<string>("");
    const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
    const [hasButton, setHasButton] = React.useState<boolean>(false);
    const [regist, setRegist] = React.useState<boolean>(false);

    React.useEffect(() => {
        setOpen(props.open);
        let fiveMeters = ''
        let marketCut = ''
        if (['D004', 'D005', 'D007', 'D008'].includes(objLocationBase.task_id)) {
            if (objLocationBase.task_id === 'D004') {
                if (props.locationTraffic5mcut.approval_research_mgr_flag === '1') {
                    fiveMeters = '1'
                }
                if (props.locationConflictMarketCut.approval_research_mgr_flag === '1') {
                    marketCut = '1'
                }
            } else if (objLocationBase.task_id === 'D005') {
                if (props.locationTraffic5mcut.approval_research_gm_flag === '1') {
                    fiveMeters = '1'
                }
                if (props.locationConflictMarketCut.approval_research_gm_flag === '1') {
                    marketCut = '1'
                }
            } else if (objLocationBase.task_id === 'D007') {
                if (props.locationTraffic5mcut.approval_mgr_flag === '1') {
                    fiveMeters = '1'
                }
                if (props.locationConflictMarketCut.approval_mgr_flag === '1') {
                    marketCut = '1'
                }
            } else if (objLocationBase.task_id === 'D008') {
                if (props.locationTraffic5mcut.approval_gm_flag === '1') {
                    fiveMeters = '1'
                }
                if (props.locationConflictMarketCut.approval_gm_flag === '1') {
                    marketCut = '1'
                }
            } else if (objLocationBase.task_id === 'A001') {
                if (props.locationTraffic5mcut.approval_gm_flag === '1') {
                    fiveMeters = '1'
                }
                if (props.locationConflictMarketCut.approval_gm_flag === '1') {
                    marketCut = '1'
                }
            }
            dispatch(setApprovalStatus({
                ...objApproval5mcut,
                fiveMeters: fiveMeters,
                marketCut: marketCut,
            }));
        }

    }, [props.open])

    const handleClose = () => {
        dispatch(setApprovalStatus({
            ...objApproval5mcut,
            "fiveMeters": "",
            "marketCut": "",
            "confirm": "",
        }));

        props.handleModal(regist)
    }


    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>, labelName: string) => {
        dispatch(setApprovalStatus({ ...objApproval5mcut, [labelName]: e.target.value }));
    }

    const handleDisabled = () => {
        let disabled = false
        if (props.locationTraffic5mcut.cut_survey === '1' && objApproval5mcut.fiveMeters === '') {
            disabled = true
        }

        if (props.locationConflictMarketCut.cut_market === '1' && objApproval5mcut.marketCut === '') {
            disabled = true
        }

        if (objLocationBase.task_id === "D004" && objApproval5mcut.confirm === '') {
            disabled = true
        }

        return disabled
    }


    const onRegist = () => {
        (async () => {
            try {
                if (utilityCtx.showSpinner) {
                    utilityCtx.showSpinner()
                }
                // メッセージモーダル表示
                setHasButton(false);
                setMessage("承認中です");
                setMessageDialogOpen(true);
                const managerName = props.userData.user_name
                let department = ""
                const staffMasterObj = localStorage.getItem("staffMaster");
                if (staffMasterObj !== null) {
                    const jsonStaffMasterObj = JSON.parse(staffMasterObj);
                    jsonStaffMasterObj.map((row: any) => {
                        if (row.department_code_oa === props.userData.department_code_OA) {
                            department = row.department_name_oa
                        }
                    })
                }

                const paythonParams = {
                    mode: "regist",
                    endPoint: "/make-iner",
                    query: {
                        prop_site_no: props.planAreaId,
                        branch: props.branch,
                        managerName: managerName,
                        department: department,
                    }
                };
                const result = await postPython(paythonParams)
                if (result.data.data.inei_file_path) {
                    const params = {
                        mode: "regist",
                        endPoint: "/location/Reception/v1/approval5mcut",
                        query: {
                            plan_area_id: props.planAreaId,
                            branch: props.branch,
                            confirm: objApproval5mcut.confirm,
                            pattern: approvalPattern(),
                            user: props.userData.staff_number,
                            department: department,
                            date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                            stamp: result.data.data.inei_file_path
                        }
                    };
                    await apiRequest(params);
                }
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                setRegist(true)
                setHasButton(true);
                setMessage("承認しました");
            } catch (e) {
                setRegist(false)
                console.log(e);
                setHasButton(true);
                setMessage("容認に失敗しました");
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }

    const approvalPattern = () => {
        if (objApproval5mcut.fiveMeters === '' && objApproval5mcut.marketCut === '1') {
            return '1'
        } else if (objApproval5mcut.fiveMeters === '' && objApproval5mcut.marketCut === '0') {
            return '2'
        } else if (objApproval5mcut.fiveMeters === '1' && objApproval5mcut.marketCut === '') {
            return '3'
        } else if (objApproval5mcut.fiveMeters === '0' && objApproval5mcut.marketCut === '') {
            return '4'
        } else if (objApproval5mcut.fiveMeters === '1' && objApproval5mcut.marketCut === '1') {
            return '5'
        } else if (objApproval5mcut.fiveMeters === '1' && objApproval5mcut.marketCut === '0') {
            return '6'
        } else if (objApproval5mcut.fiveMeters === '0' && objApproval5mcut.marketCut === '1') {
            return '7'
        } else if (objApproval5mcut.fiveMeters === '0' && objApproval5mcut.marketCut === '0') {
            return '8'
        }
    }

    const closeMessageDialog = () => {
        handleClose()
        setMessage("");
        setHasButton(false);
        setMessageDialogOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                sx={{ display: messageDialogOpen ? 'none' : 'block' }}
            >
                <DialogContent>
                    <Box sx={{ display: props.locationTraffic5mcut.cut_survey === "1" ? 'block' : 'none' }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box sx={{ whiteSpace: 'pre-wrap' }}>「5m外」を承認しますか</Box>
                        </DialogContentText>
                        <RadioGroup
                            row
                            onChange={(e) => handleRadioChange(e, 'fiveMeters')}
                            value={objApproval5mcut.fiveMeters}
                        >
                            <FormControlLabel
                                value={"1"}
                                control={<Radio />}
                                label={'承認'}
                            />
                            <FormControlLabel
                                value={"0"}
                                control={<Radio />}
                                label={'差戻'}
                            />
                        </RadioGroup>
                    </Box>
                    <Box sx={{ display: props.locationConflictMarketCut.cut_market === "1" ? 'block' : 'none' }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box sx={{ whiteSpace: 'pre-wrap' }}>「商圏カット」を承認しますか</Box>
                        </DialogContentText>
                        <RadioGroup
                            row
                            onChange={(e) => handleRadioChange(e, 'marketCut')}
                            value={objApproval5mcut.marketCut}
                        >
                            <FormControlLabel
                                value={"1"}
                                control={<Radio />}
                                label={'承認'}
                            />
                            <FormControlLabel
                                value={"0"}
                                control={<Radio />}
                                label={'差戻'}
                            />
                        </RadioGroup>
                    </Box>
                    <Box sx={{ display: objLocationBase.task_id === "D004" ? 'block' : 'none' }}>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box sx={{ whiteSpace: 'pre-wrap' }}>RR GM確認が必要ですか</Box>
                        </DialogContentText>
                        <RadioGroup
                            row
                            onChange={(e) => handleRadioChange(e, 'confirm')}
                            value={objApproval5mcut.confirm}
                        >
                            <FormControlLabel
                                value={"1"}
                                control={<Radio />}
                                label={'必要'}
                            />
                            <FormControlLabel
                                value={"0"}
                                control={<Radio />}
                                label={'不要'}
                            />
                        </RadioGroup>
                    </Box>


                    <Box sx={{ display: 'flex', justifyContent: "space-around" }}>
                        <Box sx={{ textAlign: 'center', margin: '10px' }}>
                            <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                onClick={handleClose}
                            >キャンセル</Button>
                        </Box>
                        <Box sx={{ textAlign: 'center', margin: '10px' }}>
                            <Button
                                variant="contained"
                                color="success"
                                size="small"
                                disabled={handleDisabled()}
                                onClick={onRegist}
                            >送信</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <MessageDialog props={{
                open: messageDialogOpen,
                handleModal: closeMessageDialog,
                body: message,
                hasButton: hasButton,
                buttonBody: LOCAL_CONSTANT.LABEL.CLOSE
            }} />
        </div>
    );
}

export default Approval5mcutModal;


