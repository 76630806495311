import {
    Button,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Approval5mcutSelectModal from '../subPage/Approval5mcut/Approval5mcutSelectModal';

interface ModalProps {
    props: {
        modalOpen: boolean,
        setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
        planAreaId: string,
        obj: dataTypeList[] | undefined
    }
};

interface dataTypeList {
    id: string,
    conversion_create_date: string,
    elapsedTime: string,
    type: string,
    department: string,
    district: string,
    rfc: string,
    plan_area_id: string,
    candidateSiteName: string,
    assembly: string,
    pattern: string,
    branch: string,
    status_id: string,
    pic_type: string,
    pic_name: string,
    task_id: string,
    reception_no: string
}

//TableCellのヘッダースタイル変更
const TableCellSx = {
    backgroundColor: "#00CC5B",
    color: "#fff",
};
//TableRowのカーソルをポインターへ変更
const TableRowSx = {
    cursor: "pointer"
};

const ResearchBranchModal: React.FC<ModalProps> = ({ props }) => {

    const navigate = useNavigate();

    const [propsObj, setPropsObj] = useState<dataTypeList[]>();

    const [approvalOpen, setApprovalOpen] = useState<boolean>(false);
    const [planAreaId, setPlanAreaId] = useState("")
    const [branch, setBranch] = useState("")

    //調査書(ロングページ)表示
    const handleRowClick = (branchType: string, task_id: string) => {
        let url = ""
        if (['D004', 'D005', 'D007', 'D008', 'D010'].includes(task_id)) {
            // 【立地調査リリース後対応】5m外、商圏カット承認用ページ
            setPlanAreaId(props.planAreaId)
            setBranch(branchType)
            setApprovalOpen(true)
            // url = `/approval-5mcut?planAreaId=${props.planAreaId}&branch=${branchType}`;
            // } else if (['E001', 'E010', 'E014', 'E012', 'E002', 'E003', 'E011', 'E015', 'E013'].includes(task_id)) {
            //   // 【立地調査リリース後対応】追加調査承認ページ
            //   url = `/approval-survey?planAreaId=${props.planAreaId}&branch=${branchType}`;
        } else {
            url = `/LongPage?planAreaId=${props.planAreaId}&branch=${branchType}`;
            window.open(url);
        }
    };

    //モーダルクローズ
    const handleResearchBranchClose = () => {
        props.setModalOpen(false);
    };

    //フラグ値セット関数
    const setFlagNo = (receptionNo: string) => {
        let flagNo: string = '';
        if (receptionNo !== null) {
            flagNo = receptionNo.slice(8, -1);
        }
        return flagNo;
    };

    //ポータルコンポーネントからのデータセット
    useEffect(() => {
        const obj = props.obj;
        //受付No(10桁)からフラグ値抽出
        if (typeof obj !== "undefined") {
            obj.map((elem, i) => {
                const flagNo = setFlagNo(elem.reception_no);
                obj[i]["reception_no"] = flagNo;
            })
            setPropsObj(obj);
        }
    }, [props.modalOpen]);

    const registPDF = (location: string, value: any) => {
        const newTabUrl = `${window.location.origin}${location}?planAreaId=${value.plan_area_id}&branch=${value.branch}`;
        window.open(newTabUrl);
    }

    const closeApprovalModal = (hasOpen: boolean) => {
        setApprovalOpen(hasOpen)
    }

    return (
        <Box>
            {/* 調査書枝選択画面モーダル */}
            <Dialog open={props.modalOpen} onClose={handleResearchBranchClose} fullWidth>
                <DialogTitle sx={{ textAlign: "center" }}>閲覧する枝を選択してください</DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={TableCellSx}>ステータス</TableCell>
                                    <TableCell sx={TableCellSx}>更新日時</TableCell>
                                    <TableCell sx={TableCellSx}>フラグ</TableCell>
                                    <TableCell sx={TableCellSx}>枝</TableCell>
                                    <TableCell sx={TableCellSx}>PDF</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {typeof propsObj !== "undefined" &&
                                    propsObj.map((value: any, index: any) => (
                                        <TableRow key={index} sx={TableRowSx} onClick={() => { handleRowClick(value.branch, value.task_id) }}>
                                            <TableCell>{value.status_id}</TableCell>
                                            <TableCell>{value.conversion_create_date}</TableCell>
                                            <TableCell>{value.reception_no}</TableCell>
                                            <TableCell>{value.branch}</TableCell>
                                            <TableCell>
                                                <Button
                                                    variant="contained" color="success" size="small"
                                                    onClick={() => registPDF("/PdfSummary", value)}
                                                >PDF</Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
            </Dialog>
            <Approval5mcutSelectModal props={{
                open: approvalOpen,
                handleModal: closeApprovalModal,
                planAreaId: planAreaId,
                branch: branch,
            }} />
        </Box>
    );
};

export default ResearchBranchModal;
