import React from "react";

import { useUserContext } from "../../../user-provider";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    Box,
    Button,
} from '@mui/material';

export interface modalProps {
    props: {
        open: boolean,
        handleModal: any,
        planAreaId: string | null,
        branch: string | null,
    }
}


const Approval5mcutSelectModal: React.FC<modalProps> = ({ props }) => {
    const user = useUserContext();
    const [open, setOpen] = React.useState(false);

    const [userData, setUserData] = React.useState<any>({});
    const [hasApprovalButton, setHasApprovalButton] = React.useState<boolean>(true);

    React.useEffect(() => {
        setOpen(props.open);
        if (props.open) {
            if (JSON.parse(user.userData || 'null') !== null) {
                setUserData(JSON.parse(user.userData))
            }
        }
    }, [props.open])

    React.useEffect(() => {
        if (userData) {
            const staffMasterObj = localStorage.getItem("staffMaster");
            if (staffMasterObj !== null) {
                const jsonStaffMasterObj = JSON.parse(staffMasterObj);
                jsonStaffMasterObj.map((elem: any) => {
                    if (elem.staff_number === userData.staff_number) {
                        console.log(elem.staff_number)
                        // Mgr：230 GM：101 AGM：102
                        if (["230", "101", "102"].includes(elem.staff_number)) {
                            setHasApprovalButton(false)
                        }
                    }
                })
                setHasApprovalButton(false)
            }
        }
    }, [userData])

    const handleClose = () => {
        props.handleModal(false)
    };

    const pageTransition = (page: string) => {
        let url = `/LongPage?planAreaId=${props.planAreaId}&branch=${props.branch}`;
        if (page === 'approval') {
            url = `/approval-5mcut?planAreaId=${props.planAreaId}&branch=${props.branch}`;
        }
        // モーダル削除
        props.handleModal(false)

        window.open(url);
    }

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                sx={{ zIndex: 10000 }}
                id="ABSD"
            >
                <DialogContent>
                    <Box>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box sx={{ whiteSpace: 'pre-wrap' }}>5m外・商圏カット承認があります。</Box>
                        </DialogContentText>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            color="success"
                            size="medium"
                            sx={{ margin: '12px 0', padding: '6px 48px' }}
                            onClick={(e) => { pageTransition('location') }}
                        >調査書</Button>
                    </Box>
                    <Box sx={{ textAlign: 'center' }}>
                        <Button
                            variant="contained"
                            color="success"
                            size="medium"
                            sx={{ margin: '12px 0', padding: '6px 48px' }}
                            onClick={(e) => { pageTransition('approval') }}
                            disabled={hasApprovalButton}
                        >承認用</Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default Approval5mcutSelectModal;


