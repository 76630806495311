import React from "react";

import {
    Dialog,
    DialogContent,
    DialogContentText,
    Box,
    Button,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { useUtilityContext } from '../../../utility-provider';
import { setApprovalStatus } from "../../../features/approvalSurveySlice";
import { apiRequest, postPython } from "../../../api/ApiCall";
import MessageDialog from '../../components/MessageDialog';
import { LOCAL_CONSTANT } from "../../Const";


export interface approvalSurveyModalProps {
    props: {
        open: boolean,
        handleModal: any,
        planAreaId: string | null,
        branch: string | null,
        userData: any,
        receptionBook: any,
    }
}


const ApprovalSurveyModal: React.FC<approvalSurveyModalProps> = ({ props }) => {
    const dispatch = useAppDispatch();
    const utilityCtx = useUtilityContext();

    const [open, setOpen] = React.useState(false);

	const objLocationAddSurvey = useAppSelector((state) => state.locationAddSurvey.obj);
    const objApprovalSurvey = useAppSelector((state) => state.approvalSurvey.approvalStatus);
    const [message, setMessage] = React.useState<string>("");
    const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
    const [hasButton, setHasButton] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (props.open) {
            setOpen(props.open);
        }
    }, [props.open])

    const handleClose = () => {
        dispatch(setApprovalStatus({
            ...objApprovalSurvey,
            "additionalResearch": "",
        }));
        setOpen(false)
        props.handleModal()
    }


    const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>, labelName: string) => {
        dispatch(setApprovalStatus({ ...objApprovalSurvey, [labelName]: e.target.value }));
    }

    const handleDisabled = () => {
        let disabled = false
        if (objApprovalSurvey.additionalResearch === '') {
            disabled = true
        }

        return disabled
    }

    const onSubmit = () => {
        if (
            objApprovalSurvey.additionalResearch === '0'
        ) {
            // メール
            console.log('// メール')
        } else {
            console.log('// 送信')
            onRegist()
        }
    }

    const onRegist = () => {
        (async () => {
            try {
                if (utilityCtx.showSpinner) {
                    utilityCtx.showSpinner()
                }
                // メッセージモーダル表示
                setHasButton(false);
                setMessage("承認中です");
                setMessageDialogOpen(true);
                const managerName = props.userData.user_name
                let department = ""
                const staffMasterObj = localStorage.getItem("staffMaster");
                if (staffMasterObj !== null) {
                    const jsonStaffMasterObj = JSON.parse(staffMasterObj);
                    jsonStaffMasterObj.map((row: any) => {
                        if (row.department_code_oa === props.userData.department_code_OA) {
                            department = row.department_name_oa
                        }
                    })
                }

                const paythonParams = {
                    mode: "regist",
                    endPoint: "/make-iner",
                    query: {
                        prop_site_no: props.planAreaId,
                        branch: props.branch,
                        managerName: managerName,
                        department: department,
                    }
                };
                const result = await postPython(paythonParams)

                if (result.data.data.inei_file_path) {
                    const params = {
                        mode: "regist",
                        endPoint: "/location/Reception/v1/approvalAddSurvey",
                        query: {
                            plan_area_id: props.planAreaId,
                            branch: props.branch,
                        }
                    };
                    await apiRequest(params);
                }
                // PDF変換
                await postPython({
                    mode: "regist",
                    endPoint: "/excel-add-print-pdf",
                    query: {
                        plan_area_id: props.planAreaId,
                        branch: props.branch,
                        file_name: objLocationAddSurvey.cover_excel_path.toString().replace('/excel/', ''),
                        task_id: props.receptionBook?.task_id,
                        mode: "approval",
                    }
                })

                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                setHasButton(true);
                setMessage("承認しました");
            } catch (e) {
                console.log(e);
                setHasButton(true);
                setMessage("容認に失敗しました");
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }

    const closeMessageDialog = () => {
        handleClose()
        setMessage("");
        setHasButton(false);
        setMessageDialogOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                aria-describedby="alert-dialog-slide-description"
                sx={{ display: messageDialogOpen ? 'none' : 'block' }}
            >
                <DialogContent>
                    <Box>
                        <DialogContentText id="alert-dialog-slide-description">
                            <Box sx={{ whiteSpace: 'pre-wrap' }}>「追加調査」を承認しますか</Box>
                        </DialogContentText>
                        <RadioGroup
                            row
                            onChange={(e) => handleRadioChange(e, 'additionalResearch')}
                            value={objApprovalSurvey.additionalResearch}
                        >
                            <FormControlLabel
                                value={"1"}
                                control={<Radio />}
                                label={'承認'}
                            />
                            <FormControlLabel
                                value={"0"}
                                control={<Radio />}
                                label={'差戻'}
                            />
                        </RadioGroup>
                    </Box>

                    <Box sx={{ display: 'flex', justifyContent: "space-around" }}>
                        <Box sx={{ textAlign: 'center', margin: '10px' }}>
                            <Button
                                variant="outlined"
                                color="error"
                                size="small"
                                onClick={handleClose}
                            >キャンセル</Button>
                        </Box>
                        <Box sx={{ textAlign: 'center', margin: '10px' }}>
                            <Button
                                variant="contained"
                                color="success"
                                size="small"
                                disabled={handleDisabled()}
                                onClick={onSubmit}
                            >送信</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
            <MessageDialog props={{
                open: messageDialogOpen,
                handleModal: closeMessageDialog,
                body: message,
                hasButton: hasButton,
                buttonBody: LOCAL_CONSTANT.LABEL.CLOSE
            }} />
        </div>
    );
}

export default ApprovalSurveyModal;


