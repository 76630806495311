import * as React from 'react';

import {
    Box,
    Button,
    Typography,
} from '@mui/material';
import {
    dateDisplayByType,
    timeDisplay,
    borderStyle,
    borderStyleColor,
    checkTrafficTime,
    changeParams,
} from "./TrafficVolumeFunction";
import { useUtilityContext } from '../../../utility-provider';
import { apiRequest } from "../../../api/ApiCall";
import {
    LOCAL_CONSTANT
} from "../../Const";
import { useAppSelector } from '../../../app/hooks';
import MessageDialog from '../../components/MessageDialog';
import ConfirmDialog from '../../components/ConfirmDialog';
import { locationTrafficObj } from '../../../data/type';

export interface changePeopleProps {
    props: {
        trafficVolumeList: any;
        handleClose: any;
        handleCloseRegist: any;
        targetTraffic: any;
        target: string;
    }
}

const ChangePeople: React.FC<changePeopleProps> = ({ props }) => {
    const [list, setList] = React.useState([]);

    const utilityCtx = useUtilityContext();
    const objLongPageParam = useAppSelector((state) => state.longPage.param);

    const [message, setMessage] = React.useState<string>("");
    const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
    const [hasButton, setHasButton] = React.useState<boolean>(false);
    const [innerWidth, setInnerWidth] = React.useState("");
    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(false);
    const [changeTraffic, setChangeTraffic] = React.useState(locationTrafficObj)
    const [inOut, setInOut] = React.useState<boolean>(false);

    React.useEffect(() => {
        setInnerWidth((Math.round(Number(window.innerWidth) * 0.85)).toString() + 'px')
    }, [window.innerWidth])

    // 閉じる
    const handleClose = () => {
        props.handleClose()
    };
    const handleCloseRegist = () => {
        props.handleCloseRegist()
    };

    React.useEffect(() => {
        const tmpCarList: any = []
        props.trafficVolumeList.map((row: any) => {
            tmpCarList.push(row)
        })
        setList(tmpCarList)
    }, [props.trafficVolumeList])

    const closeMessageDialog = () => {
        setMessage("");
        setHasButton(false);
        setMessageDialogOpen(false);
        // 閉じる
        handleCloseRegist()
    };

    const changeHandle = (row: any, inOutVal: boolean) => {
        setInOut(inOutVal)
        setChangeTraffic(row)
        if (checkTrafficTime(row, props.targetTraffic) !== "#FFFFFF") {
            setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.CHANGE_TRAFFIC_CONFIRM)
            setConfirmDialogOpen(true)
        } else {
            changeResult()
        }
    }

    const changeCancel = () => {
        setChangeTraffic(locationTrafficObj)
        setConfirmDialogOpen(false)
        setMessage("")
    }

    const changeResult = () => {
        const params = changeParams(changeTraffic, props.targetTraffic, objLongPageParam.planAreaId, inOut)
        changeRegist(params)
    }

    const changeRegist = (params: any) => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UPDATE_START_TRAFFIC_PEOPLE);
                setMessageDialogOpen(true);
                await apiRequest(params);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UPDATE_TRAFFIC_PEOPLE);
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UPDATE_ERROR_TRAFFIC_PEOPLE);
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }

    const borderStyleColorVertical = (width: number, height: number) => {
        return {
            width: width + '%',
            height: height + 'px',
            backgroundColor: '#D9D9D9',
            color: "black",
            border: 'solid 1px rgba(224, 224, 224, 1)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '-ms-writing-mode': 'tb-rl',
            writingMode: 'vertical-rl',
        }
    }

    return (
        <>
            <Box sx={{ width: innerWidth, height: '100%', minHeight: '100%', maxHeight: '100%', padding: '20px' }}>
                <Box sx={{ width: '100%', height: '85%', minHeight: '85%', maxHeight: '85%' }}>
                    <Box sx={{ width: '100%', height: '100%', minHeight: '100%', maxHeight: '100%' }}>
                        <Box sx={{ width: '98%', height: '170px', minHeight: '170px', maxHeight: '170px', textAlign: 'center', display: 'flex', backgroundColor: '#D9D9D9', color: "#fff", border: '1px solid rgba(224, 224, 224, 1)' }}>
                            <Box sx={{ width: '20%', minWidth: '20%', maxWidth: '20%', height: '100%' }}>
                                <Typography sx={borderStyleColor(100, 100)}>{LOCAL_CONSTANT.LABEL.COUNT_DATE_TIME}</Typography>
                                <Box sx={{ display: 'flex', height: '50px' }}>
                                    <Typography sx={borderStyleColor(50, 70)}>{LOCAL_CONSTANT.LABEL.DATE_AND_TIME}</Typography>
                                    <Typography sx={borderStyleColor(50, 70)}>{LOCAL_CONSTANT.LABEL.TIME}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ width: '30%', minWidth: '30%', maxWidth: '30%', height: '100%', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                <Typography sx={borderStyleColor(100, 50)}>{LOCAL_CONSTANT.LABEL.IN_FIVE}</Typography>
                                <Box sx={{ width: '100%', height: '120px', display: 'flex' }}>
                                    <Typography sx={borderStyleColorVertical(20, 120)}>{LOCAL_CONSTANT.LABEL.STUDENT}</Typography>
                                    <Box sx={{ width: '30%' }}>
                                        <Typography sx={borderStyleColor(100, 50)}>{LOCAL_CONSTANT.LABEL.OVER_SIXTY_FIVE}</Typography>
                                        <Box sx={{ display: 'flex', height: '50px' }}>
                                            <Typography sx={borderStyleColor(50, 70)}>{LOCAL_CONSTANT.LABEL.MALE}</Typography>
                                            <Typography sx={borderStyleColor(50, 70)}>{LOCAL_CONSTANT.LABEL.FEMALE}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: '30%' }}>
                                        <Typography sx={borderStyleColor(100, 50)}>{LOCAL_CONSTANT.LABEL.OTHER}</Typography>
                                        <Box sx={{ display: 'flex', height: '50px' }}>
                                            <Typography sx={borderStyleColor(50, 70)}>{LOCAL_CONSTANT.LABEL.MALE}</Typography>
                                            <Typography sx={borderStyleColor(50, 70)}>{LOCAL_CONSTANT.LABEL.FEMALE}</Typography>
                                        </Box>
                                    </Box>
                                    <Typography sx={borderStyleColorVertical(20, 120)}>{LOCAL_CONSTANT.LABEL.BICYCLE}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ width: '30%', minWidth: '30%', maxWidth: '30%', height: '100%', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                <Typography sx={borderStyleColor(100, 50)}>{LOCAL_CONSTANT.LABEL.OUT_FIVE}</Typography>
                                <Box sx={{ width: '100%', height: '120px', display: 'flex' }}>
                                    <Typography sx={borderStyleColorVertical(20, 120)}>{LOCAL_CONSTANT.LABEL.STUDENT}</Typography>
                                    <Box sx={{ width: '30%' }}>
                                        <Typography sx={borderStyleColor(100, 50)}>{LOCAL_CONSTANT.LABEL.OVER_SIXTY_FIVE}</Typography>
                                        <Box sx={{ display: 'flex', height: '50px' }}>
                                            <Typography sx={borderStyleColor(50, 70)}>{LOCAL_CONSTANT.LABEL.MALE}</Typography>
                                            <Typography sx={borderStyleColor(50, 70)}>{LOCAL_CONSTANT.LABEL.FEMALE}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: '30%' }}>
                                        <Typography sx={borderStyleColor(100, 50)}>{LOCAL_CONSTANT.LABEL.OTHER}</Typography>
                                        <Box sx={{ display: 'flex', height: '50px' }}>
                                            <Typography sx={borderStyleColor(50, 70)}>{LOCAL_CONSTANT.LABEL.MALE}</Typography>
                                            <Typography sx={borderStyleColor(50, 70)}>{LOCAL_CONSTANT.LABEL.FEMALE}</Typography>
                                        </Box>
                                    </Box>
                                    <Typography sx={borderStyleColorVertical(20, 120)}>{LOCAL_CONSTANT.LABEL.BICYCLE}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ width: '20%', minWidth: '20%', maxWidth: '20%', height: '100%', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                <Typography sx={borderStyleColor(100, 100)}>{LOCAL_CONSTANT.LABEL.SELECT_COUNT_DATE}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ textAlign: 'center', width: '99.6%', height: '60%', minHeight: '60%', maxHeight: '60%', overflow: 'scroll' }}>
                            {list.map((row: any) => (
                                <Box
                                    key={row.uuid}
                                    sx={{ display: 'flex', backgroundColor: checkTrafficTime(row, props.targetTraffic) }}
                                >
                                    <Box sx={{ width: '20%', minWidth: '20%', maxWidth: '20%', display: 'flex' }}>
                                        <Typography sx={borderStyle(50, 50)}>{dateDisplayByType(row, "", "yearday")}</Typography>
                                        <Typography sx={borderStyle(50, 50)}>{timeDisplay(row, "")}</Typography>
                                    </Box>
                                    <Box sx={{ width: '30%', minWidth: '30%', maxWidth: '30%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                        <Typography sx={borderStyle(20, 50)}>{row.people_5m_in_student}</Typography>
                                        <Typography sx={borderStyle(15, 50)}>{row.people_5m_in_male_65over}</Typography>
                                        <Typography sx={borderStyle(15, 50)}>{row.people_5m_in_female_65over}</Typography>
                                        <Typography sx={borderStyle(15, 50)}>{row.people_5m_in_male_other}</Typography>
                                        <Typography sx={borderStyle(15, 50)}>{row.people_5m_in_female_other}</Typography>
                                        <Typography sx={borderStyle(20, 50)}>{row.people_5m_in_bicycle}</Typography>
                                    </Box>
                                    <Box sx={{ width: '30%', minWidth: '30%', maxWidth: '30%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                        <Typography sx={borderStyle(20, 50)}>{row.people_5m_out_student}</Typography>
                                        <Typography sx={borderStyle(15, 50)}>{row.people_5m_out_male_65over}</Typography>
                                        <Typography sx={borderStyle(15, 50)}>{row.people_5m_out_female_65over}</Typography>
                                        <Typography sx={borderStyle(15, 50)}>{row.people_5m_out_male_other}</Typography>
                                        <Typography sx={borderStyle(15, 50)}>{row.people_5m_out_female_other}</Typography>
                                        <Typography sx={borderStyle(20, 50)}>{row.people_5m_out_bicycle}</Typography>
                                    </Box>
                                    <Box sx={{ width: '20%', minWidth: '20%', maxWidth: '20%', display: 'flex', justifyContent: 'space-around', padding: '10px 0', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            size="small"
                                            onClick={() => changeHandle(row, false)}
                                        >5m内入替</Button>
                                        <Button
                                            variant="contained"
                                            color="success"
                                            size="small"
                                            onClick={() => changeHandle(row, true)}
                                        >5m内外入替</Button>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ height: '15%', minHeight: '15%', maxHeight: '15%' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', padding: '10px' }}>
                        <Button variant="outlined" color="error" size="large" onClick={handleClose}>{LOCAL_CONSTANT.LABEL.CANCEL}</Button>
                    </Box>
                </Box>
            </Box>
            <MessageDialog props={{
                open: messageDialogOpen,
                handleModal: closeMessageDialog,
                body: message,
                hasButton: hasButton,
                buttonBody: LOCAL_CONSTANT.LABEL.CLOSE
            }}
            />
            <ConfirmDialog props={{
                open: confirmDialogOpen,
                handleModal: setConfirmDialogOpen,
                onCancel: changeCancel,
                onExecution: changeResult,
                mode: "changeConfirm",
                body: message,
                confirmCancel: LOCAL_CONSTANT.LABEL.CANCEL,
                confirmOk: LOCAL_CONSTANT.LABEL.UPDATE
            }}
            />
        </>
    );
}

export default ChangePeople;