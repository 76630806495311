import { getTableName, getTableColumnName, TextChangeColor, TextDefaultColor, TextDisabledColor, BGDefaultColor, BGChangeColor, BGDisabledColor } from "./table";

//文字色判定
export const changeTextColor = (isUserType: boolean, objRrBranchLog: any, pageName: string, labelName: string) => {
    let changeColor = false;
    changeColor = textColor(objRrBranchLog, pageName, labelName);
    // BQにログが存在すれば青文字に変更
    if (changeColor) {
        return TextChangeColor;
    } else {
        // BQにログが存在せず非活性の場合はグレー文字に変更
        // 非活性がtrue 活性がfalse
        if (isUserType) {
            return TextDisabledColor;
            // BQにログが存在せず活性の場合は黒文字に変更
        } else {
            return TextDefaultColor;
        }
    }
};

// 最終更新が992002の場合テキストカラーの変更
export const textColor = (objRrBranchLog: any, pageName: string, labelName: string) => {
    let changeColor = false;
    if (Object.keys(objRrBranchLog).length > 0) {
        const [tableName, columnName, update_department] = checkLogPage(pageName, labelName);
        if (objRrBranchLog[tableName]) {
            objRrBranchLog[tableName].map((row: any) => {
                if (row.target_column === columnName && update_department.includes(row.update_department)) {
                    changeColor = true;
                }
            });
        }
    }
    return changeColor;
};

//文字色判定(テーブル用)
export const changeTableTextColor = (isUserType: boolean, objRrBranchLog: any, pageName: string, labelName: string, rowIndex: string, seqLabel: string) => {
    let changeColor = false;
    changeColor = tableTextColor(objRrBranchLog, pageName, labelName, rowIndex, seqLabel);
    // BQにログが存在すれば青文字に変更
    if (changeColor) {
        return TextChangeColor;
    } else {
        // BQにログが存在せず非活性の場合はグレー文字に変更
        // 非活性がtrue 活性がfalse
        if (isUserType) {
            return TextDisabledColor;
            // BQにログが存在せず活性の場合は黒文字に変更
        } else {
            return TextDefaultColor;
        }
    }
};

// 最終更新が992002の場合テキストカラーの変更(テーブル用)
export const tableTextColor = (objRrBranchLog: any, pageName: string, labelName: string, rowIndex: string, seqLabel: string) => {
    let changeColor = false;
    if (Object.keys(objRrBranchLog).length > 0) {
        const [tableName, columnName, update_department] = checkLogPage(pageName, labelName);
        if (objRrBranchLog[tableName]) {
            objRrBranchLog[tableName].map((row: any) => {
                if (row.target_column === columnName) {
                    const subKey = JSON.parse(row.sub_key.includes("'") ? JSON.stringify(row.sub_key).replaceAll('"', "").replaceAll("'", '"') : row.sub_key);
                    if (subKey[seqLabel] === rowIndex && update_department.includes(row.update_department)) {
                        changeColor = true;
                    }
                }
            });
        }
    }

    return changeColor;
};

// 影響度
export const changeImpactTextColor = (isUserType: boolean, objRrBranchLog: any, pageName: string, labelName: string, subKeyStoreCode: string, subKeyTenpoName: string) => {
    let changeColor = false;
    changeColor = impactTextColor(objRrBranchLog, pageName, labelName, subKeyStoreCode, subKeyTenpoName);
    // BQにログが存在すれば青文字に変更
    if (changeColor) {
        return TextChangeColor;
    } else {
        // BQにログが存在せず非活性の場合はグレー文字に変更
        // 非活性がtrue 活性がfalse
        if (isUserType) {
            return TextDisabledColor;
            // BQにログが存在せず活性の場合は黒文字に変更
        } else {
            return TextDefaultColor;
        }
    }
};

// 影響度
// 最終更新が992002の場合テキストカラーの変更
export const impactTextColor = (objRrBranchLog: any, pageName: string, labelName: string, subKeyStoreCode: string, subKeyTenpoName: string) => {
    let changeColor = false;
    if (Object.keys(objRrBranchLog).length > 0) {
        const [tableName, columnName, update_department] = checkLogPage(pageName, labelName);
        if (objRrBranchLog[tableName]) {
            objRrBranchLog[tableName].map((row: any) => {
                if (row.target_column === columnName) {
                    //sub_keyに「store_code」と「tenpo_name」の項目がある場合
                    if (row.sub_key.includes("store_code") && row.sub_key.includes("tenpo_name")) {
                        // シングルクォーテーションをタブルクォーテーション変更してパース
                        const subKey = JSON.parse(row.sub_key.includes("'") ? JSON.stringify(row.sub_key).replaceAll('"', "").replaceAll("'", '"') : row.sub_key);
                        if (subKey.store_code === subKeyStoreCode && subKey.tenpo_name === subKeyTenpoName && update_department.includes(row.update_department)) {
                            changeColor = true;
                        }
                    }
                }
            });
        }
    }

    return changeColor;
};

//図面
//文字色判定
export const changeDrawingTextColor = (isUserType: boolean, objRrBranchLog: any, pageName: string, labelName: string, rowIndex: number, seqLabel: string) => {
    let changeColor = false;
    changeColor = DrawingTextColor(objRrBranchLog, pageName, labelName, rowIndex, seqLabel);
    // BQにログが存在すれば青文字に変更
    if (changeColor) {
        return TextChangeColor;
    } else {
        // BQにログが存在せず非活性の場合はグレー文字に変更
        // 非活性がtrue 活性がfalse
        if (isUserType) {
            return TextDisabledColor;
            // BQにログが存在せず活性の場合は黒文字に変更
        } else {
            return TextDefaultColor;
        }
    }
};

// 図面
// 最終更新が992002の場合テキストカラーの変更
export const DrawingTextColor = (objRrBranchLog: any, pageName: string, labelName: string, rowIndex: number, seqLabel: string) => {
    let changeColor = false;
    if (Object.keys(objRrBranchLog).length > 0) {
        const [tableName, columnName, update_department] = checkLogPage(pageName, labelName);
        if (objRrBranchLog[tableName]) {
            let logBefoerValueList: any = [];
            let logAfterValueList: any = [];
            objRrBranchLog[tableName].map((row: any) => {
                if (row.target_column === columnName) {
                    if (row.target_column === "hand_drawn_layout" && row.target_before_value.includes("{")) {
                        const logBefoerValue = JSON.parse(row.target_before_value);
                        logBefoerValueList.push(logBefoerValue);
                    }
                    if (row.target_column === "hand_drawn_layout" && row.target_after_value.includes("{")) {
                        const logAfterValue = JSON.parse(row.target_after_value);
                        logAfterValueList.push(logAfterValue);
                    }
                    if (process.env.REACT_APP_MODE === "local" || process.env.REACT_APP_MODE === "dev") {
                        if (
                            logBefoerValueList[0]?.[rowIndex]?.[seqLabel] != logAfterValueList[0]?.[rowIndex]?.[seqLabel] &&
                            (row.update_department === "992002" || row.update_department === "950404")
                        ) {
                            changeColor = true;
                        }
                    } else {
                    }
                    if (logBefoerValueList[0]?.[rowIndex]?.[seqLabel] != logAfterValueList[0]?.[rowIndex]?.[seqLabel] && update_department.includes(row.update_department)) {
                        changeColor = true;
                    }

                    //配列リセット
                    logBefoerValueList.shift();
                    logAfterValueList.shift();
                }
            });
        }
    }
    return changeColor;
};

//背景色判定 テキストフィールド、プルダウン
export const changeBGColor = (isUserType: boolean, value: any) => {
    if (isUserType) {
        return BGDisabledColor;
    } else if (value.length > 0) {
        return BGChangeColor;
    }
    return BGDefaultColor;
};

//背景色判定 チェックボックス
export const changeCheckBoxBGColor = (isUserType: boolean, value: boolean) => {
    if (isUserType) {
        return BGDisabledColor;
    } else if (value) {
        return BGChangeColor;
    }
    return BGDefaultColor;
};

const init_update_department = () => {
    let update_department = ["992002"];
    if (process.env.REACT_APP_MODE === "local" || process.env.REACT_APP_MODE === "dev") {
        update_department = ["992002", "950404"];
    }

    return update_department;
};

const checkLogPage = (pageName: string, labelName: string) => {
    const tableName = getTableName(pageName);
    const columnName = getTableColumnName(tableName, labelName);
    const update_department = init_update_department();

    return [tableName, columnName, update_department];
};
