
export interface PlanAreaType {
    id: string,
    uuid: string,
    centerlat: number,
    centerlon: number,
    geometry: string,
    entrance_feature: string,
    plan_area_feature: string,
    building_feature: string,
    citycode: string,
    display_flag: string,
    deletable_flag: string,
    request_flag: string,
    edit_flag: string,
    corporate_flag: string,
    plan_area_name: string,
    step: number,
    plan_area_address: string,
    plan_area_zipcode: string,
    plan_area_townname: string,
    plan_area_townname_serial_number: string,
    zone_id: string,
    plan_area_area_size: number,
    building_area_size: number,
    plan_area_current_state: string,
    plan_area_owner_id: string,
    plan_area_amount_sales: string,
    latlon_jpn: string,
    do_code: string,
    dm_confirm: string,
    rm_confirm: string,
    existing_best_location_flag: string,
    existing_multi_flag: string,
    evaluation_score: string,
    evaluation_version: string,
    visit_activity: string,
    current_situation1: string,
    current_situation2: string,
    minor_classification1: string,
    minor_classification2: string,
    tenant_name1: string,
    tenant_name2: string,
    branch_name1: string,
    branch_name2: string,
    remarks: string,
    pending_flag: string,
    giving_up_reason: string,
    store_direction: string,
    create_date: string,
    update_date: string,
    zone_is_partial_step: string,
    zone_plus_one_existing_feature: string,
    zone_scrap_existing_feature: string,
    zone_pincer_attack_flag: string,
}

export interface existingSejStoreType {
    store_code: string,
    ctycode: string,
    tenpo_name: string,
    tenpo_address: string,
    prop_site_no: string,
    step: number,
    centerlat: number,
    centerlon: number,
    geohash: string,
    openingdate: string,
    closingdate: string,
    doname: string,
    dmname: string,
    ofcname: string,
    zoname: string,
    contract_type: string,
    parkingarea: number,
    sales_forecast: number,
    salesfloor: number,
    liquorlicense: string,
    tobaccolicense: string,
    plan_area_feature: string,
    building_feature: string,
    entrance_feature: string,
    existing_best_location_flag: string,
    existing_multi_flag: string,
    store_direction: string,
    sales_type: string,
}


export interface impactCounterType {
    uuid: string;
    seq: number;
    centerlat: number;
    centerlon: number;
    shape: string;
    create_date: string;
    update_date: string;
    delete_flag: string;
    name: string;
    count_date: number;
    count_time: number;
    top_car_left: number;
    top_car_bottom: number;
    top_car_right: number;
    top_people_left: number;
    top_people_bottom: number;
    top_people_right: number;
    left_car_top: number;
    left_car_bottom: number;
    left_car_right: number;
    left_people_top: number;
    left_people_bottom: number;
    left_people_right: number;
    bottom_car_top: number;
    bottom_car_left: number;
    bottom_car_right: number;
    bottom_people_top: number;
    bottom_people_left: number;
    bottom_people_right: number;
    right_car_top: number;
    right_car_left: number;
    right_car_bottom: number;
    right_people_top: number;
    right_people_left: number;
}

export interface conflictStoreType {
    id: string;
    uuid: string;
    centerlat: number;
    centerlon: number;
    bland_id: string;
    bland_name: string;
    tenpo_name: string;
    comment: string;
    open_date: string;
    close_date: string;
    type: string;
    handle_category_tobacco: string;
    handle_category_liquor: string;
}

export interface conflictStoreListInterFace {
    key: number,
    data: conflictStoreType[],
    marker: google.maps.Marker | google.maps.Polygon,
}

export interface listInterFace {
    key: number,
    data: any,
    marker: google.maps.Marker | google.maps.Polygon,
}

export interface storeForimpactCounterInterFace {
    key: string,
    data: any,
    marker: google.maps.Marker,
    path: any,
    counter: any,
}

export interface impactCounterInterFace {
    key: string,
    data: any,
    arrowPoly: google.maps.Polyline,
    dummy: google.maps.Polyline,
}

export interface impactCounterLabelType {
    key: string,
    data: any,
    marker: google.maps.Marker,
}

export interface latlngLiteral {
    lat: number,
    lng: number
}

export const zIndexOrder = {
    Walk5minCircle: 100,
    kouhochiPolygon: 100,
    buildingPolygon: 101,
    buildingEntrance: 102,
    // 所有者賃貸人
    ownerLessor: 103,
    // 編集モード時は一律300
    zoneEditPolygon: 300,
    // ピンメモ系
    pinMemoPin: 201,
    pinMemoPolygon: 200,
    pinMemoLine: 202,
    pinMemoArrow: 203,
    pinMemoMarker: 299,
    // ×印は一番上
    pinMemoCrossIcon: 900,
    marketArea: 10,
    // 店舗アイコン
    existingSejStoreIcon: 410,
    existingSejSvgStoreIcon: 10,
    conflictStoreIcon: 402,
    closedSejStoreIcon: 401,
    closedConflictStoreIcon: 400,
    // 競合マーカー
    conflictMarker: 500,
    // マップサークル
    circles: 0,
    // 店舗バッジ
    badge: 450,
    // 出店戦略
    zoneStrategy: 440,
    background: 50,
    // zone
    zonePolygon: 60,
    // 影響度
    impactPolygon: 70,
    impactArrow: 80,
    impactDummy: 90,
    // 所有者賃貸人位置変更時
    editOwnerLessor: 1001,
};

export interface conflictResearchList {
    key: string,
    type: string,
    data: any,
    marker: google.maps.Marker | google.maps.Polygon,
}

export interface locationConflictType {
    plan_area_id: String,
    branch: String,
    conflict_type: String,
    conflict_sequence: String,
    type: String,
    delete_flag: String,
    conflict_id: String,
    road_shape: String,
    road_distance: String,
    direct_distance: String,
    location_conflict_type: String,
    include_commercial_area: String,
    bland_name: String,
    tenpo_name: String,
    prefectures: String,
    tenpo_address: String,
    conflict_business_type: String,
    business_hours: String,
    closing_time: String,
    handle_category_liquor: String,
    handle_category_tobacco: String,
    location: String,
    sales_floor_area_size: String,
    gondola_scale: String,
    handle_category_parking: String,
    store_frontage: String,
    parking_area_size: String,
    number_of_parking: String,
    front_parking_tangent: String,
    front_first_entrance: String,
    front_first_depth: String,
    front_second_entrance: String,
    front_second_depth: String,
    side_road_type: String,
    side_parking_tangent: String,
    side_first_entrance: String,
    side_first_depth: String,
    side_second_entrance: String,
    side_second_depth: String,
    estimated_daily_sales: String,
    plan_area_shared_road: String,
    plan_area_direction: String,
    conflict_shared_road: String,
    conflict_median_strip: String,
    count_left_right_turns: String,
    count_straight: String,
    market_cut: String,
}


export const initLocationConflict = {
    plan_area_id: '',
    branch: '',
    conflict_type: '',
    conflict_sequence: '',
    type: '',
    delete_flag: '',
    conflict_id: '',
    road_shape: '',
    road_distance: '',
    direct_distance: '',
    location_conflict_type: '',
    include_commercial_area: '',
    bland_name: '',
    tenpo_name: '',
    prefectures: '',
    tenpo_address: '',
    conflict_business_type: '',
    business_hours: '',
    closing_time: '',
    handle_category_liquor: '',
    handle_category_tobacco: '',
    location: '',
    sales_floor_area_size: '',
    gondola_scale: '',
    handle_category_parking: '',
    store_frontage: '',
    parking_area_size: '',
    number_of_parking: '',
    front_parking_tangent: '',
    front_first_entrance: '',
    front_first_depth: '',
    front_second_entrance: '',
    front_second_depth: '',
    side_road_type: '',
    side_parking_tangent: '',
    side_first_entrance: '',
    side_first_depth: '',
    side_second_entrance: '',
    side_second_depth: '',
    estimated_daily_sales: '',
    plan_area_shared_road: '',
    plan_area_direction: '',
    conflict_shared_road: '',
    conflict_median_strip: '',
    count_left_right_turns: '',
    count_straight: '',
    market_cut: '0',
    bland_code: '',
    conductor_not_included: '0',
}