import * as React from 'react';

import {
    Box,
    Modal,
} from '@mui/material';

import {
    getTrafficVolume
} from "../../../data/DataManage";
import ChangeCar from "./ChangeCar";
import ChangePeople from "./ChangePeople";
import { LOCAL_CONSTANT } from '../../Const';
import { useAppSelector } from '../../../app/hooks';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "85%",
    height: "95%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};

export interface changeTrafficVolumeModalProps {
    props: {
        open: boolean,
        handleModal: React.Dispatch<React.SetStateAction<boolean>>,
        target: string;
        setReload: any;
        targetTraffic: any;
    }
}

export const CAR_PEOPLE = '0';
export const CAR = '1';
export const PEOPLE = '2';

const ChangeTrafficVolume: React.FC<changeTrafficVolumeModalProps> = ({ props }) => {
    // 初期
    const [open, setOpen] = React.useState(false);
    const [trafficVolumeList, setTrafficVolumeList] = React.useState([])
    const objLongPageParam = useAppSelector((state) => state.longPage.param);

    // 閉じる
    const handleClose = () => {
        // 閉じる
        setOpen(false)
        props.handleModal(false)
        setTrafficVolumeList([])
    };

    const handleCloseRegist = () => {
        // 閉じる
        setOpen(false)
        props.handleModal(false)
        setTrafficVolumeList([])
        // リロード
        props.setReload(true)
    };

    React.useEffect(() => {
        if (props.open) {
            setOpen(props.open);
            callApiList()
        }
    }, [props.open])

    const callApiList = () => {
        // 候補地呼び出し
        callApi({
            plan_area_id: objLongPageParam.planAreaId,
            count_type: props.target === LOCAL_CONSTANT.VALUE.ADD_CAR
                ? [CAR_PEOPLE, CAR].join(',')
                : [CAR_PEOPLE, PEOPLE].join(',')
        })
            .then((res: any) => {
                setTrafficVolumeList(res)
            }).catch((e) => {
                console.log(e)
            }).finally(() => {
            })
    }

    const callApi = async (params: any) => {
        return await getTrafficVolume({
            mode: "getTrafficVolume",
            plan_area_id: params.plan_area_id,
            count_type: params.count_type,
        })
    }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {
                        props.target === LOCAL_CONSTANT.VALUE.CHANGE_CAR &&
                        <ChangeCar props={{
                            trafficVolumeList: trafficVolumeList,
                            handleClose: handleClose,
                            handleCloseRegist: handleCloseRegist,
                            targetTraffic: props.targetTraffic
                        }} />
                    }
                    {
                        (props.target === LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN ||
                            props.target === LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_OUT) &&
                        <ChangePeople props={{
                            trafficVolumeList: trafficVolumeList,
                            handleClose: handleClose,
                            handleCloseRegist: handleCloseRegist,
                            targetTraffic: props.targetTraffic,
                            target: props.target,
                        }} />
                    }
                </Box>
            </Modal>
        </>
    );
}


export default ChangeTrafficVolume;