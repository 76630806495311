import * as React from 'react';

import {
    Box,
    Button,
    Typography,
    Checkbox,
    Select,
    MenuItem,
    FormLabel,
    SelectChangeEvent,
} from '@mui/material';
import { blue } from '@mui/material/colors';
import { useUtilityContext } from '../../../utility-provider';
import { apiRequest } from "../../../api/ApiCall";
import {
    dateDisplayByType,
    timeDisplay,
    borderStyle,
    borderStyleColor,
    headerStyleWithBorder,
    hasDisabled,
    addTrafficParams,
    addRequestParams,
    initCountDate,
    borderStyleDisabled,
    upsertLocationTraffic5mcutParam,
    checkSelectTraffic,
    hasTime,
} from "./TrafficVolumeFunction";
import { LOCAL_CONSTANT } from "../../Const";
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setObjLocationTraffic5mcut } from '../../../features/locationTraffic5mcutSlice';
import MessageDialog from '../../components/MessageDialog';

export interface addCarProps {
    props: {
        trafficVolumeList: any;
        handleClose: any;
        handleCloseRegist: any;
        dataList: any;
    }
}
interface typeListInterface {
    [key: string]: any
}

const COUNT_TYPE_USER = '3'

const typeList = {
    'morning': 0,
    'noon': 0,
    'night': 0,
    'holidays': 0
} as typeListInterface

interface userInputDataInterface {
    [key: string]: any
}

const initUserInputData = {
    'morning': { 'key': 'morning', 'checked': false, 'disabled': false },
    'noon': { 'key': 'noon', 'checked': false, 'disabled': false },
    'night': { 'key': 'night', 'checked': false, 'disabled': false },
    'holidays': { 'key': 'holidays', 'checked': false, 'disabled': false }
} as userInputDataInterface

const checkBoxList = () => {
    return [
        { key: 'morning' },
        { key: 'noon' },
        { key: 'night' },
        { key: 'holidays' },
    ]
}

const AddCar: React.FC<addCarProps> = ({ props }) => {
    const dispatch = useAppDispatch();

    const [list, setList] = React.useState([]);
    const [checkList, setCheckList] = React.useState(JSON.parse(JSON.stringify(typeList)))
    const [redo, setRedo] = React.useState<boolean>(false)
    const [userInputData, setUserInputData] = React.useState(JSON.parse(JSON.stringify(initUserInputData)))
    const utilityCtx = useUtilityContext();
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    // 通行量(5m外歩行者TR算入確認)
    const objLocationTraffic5mcut = useAppSelector((state) => state.locationTraffic5mcut.obj);
    // 通行量 その他 駅出入口の名称と距離 
    const objStation = useAppSelector((state) => state.locationTraffic5mcut.stationObj);
    // 通行量 その他 大学短大の名称と距離 
    const objSchool = useAppSelector((state) => state.locationTraffic5mcut.schoolObj);
    const [separateInvestigation, setSeparateInvestigation] = React.useState<any>();
    const [separateValue, setSeparateValue] = React.useState<string>(objLocationTraffic5mcut.car_another_survey)
    const [message, setMessage] = React.useState<string>("");
    const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
    const [hasButton, setHasButton] = React.useState<boolean>(false);

    React.useEffect(() => {
        const res: any = localStorage.getItem("codeMaster")
        if (res) {
            const d = JSON.parse(res)
            setSeparateInvestigation(d['separate_investigation'])
        }
    }, [])

    // 閉じる
    const handleClose = () => {
        setUserInputData(initUserInputData)
        setCheckList(typeList)
        props.handleClose()
    };

    const handleCloseRegist = () => {
        setUserInputData(initUserInputData)
        setCheckList(typeList)
        props.handleCloseRegist()
    };


    React.useEffect(() => {
        const tmpCarList: any = []
        props.trafficVolumeList.map((row: any) => {
            row.morning = 0
            row.noon = 0
            row.night = 0
            row.holidays = 0
            tmpCarList.push(row)
        })
        setList(tmpCarList)
    }, [props.trafficVolumeList])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, row: any, target: string) => {
        let hasSelect = true
        if (e.target.checked === true && checkList[target] >= 2) {
            hasSelect = false
        }
        if (hasSelect) {
            let tmp: any = checkList
            if (row[target] === 1) {
                row[target] = 0
                tmp[target] -= 1
                if (tmp[target] === 0) {
                    userInputData[target].disabled = false
                    setUserInputData(userInputData)
                }
            } else {
                row[target] = row[target] + 1
                tmp[target] += 1
                userInputData[target].disabled = true
                setUserInputData(userInputData)
            }
            setRedo(!redo)
            setCheckList(tmp)
        }
    }

    const hasChecked = (val: number) => {
        return val > 0 ? true : false
    }

    const checkBoxStyle = (row: any, hasColor: boolean, key: string) => {
        let color = "#FFFFFF";
        if (hasColor) {
            color = hasTime(row, key)
        }
        return {
            width: '25%',
            border: '1px solid rgba(224, 224, 224, 1)',
            backgroundColor: color,
            borderRadius: '0',
            '&.Mui-checked': {
                color: blue[800],
            },
        }
    }

    const hasBC = (row: any) => {
        let color = '#FFFFFF'
        if (
            row.morning === 1 ||
            row.noon === 1 ||
            row.night === 1 ||
            row.holidays === 1
        ) {
            color = '#FFFF00'
        }

        return color
    }

    const handleRegist = () => {
        let errorMessage = ""
        let morning = 0
        let noon = 0
        let night = 0
        let holidays = 0
        let traffic_type_seq_morning = 0
        let traffic_type_seq_noon = 0
        let traffic_type_seq_night = 0
        let traffic_type_seq_Holidays = 0
        props.dataList.map((row: any) => {
            if (row.traffic_type === '0') {
                morning = morning + 1
                traffic_type_seq_morning = traffic_type_seq_morning + 1
            } else if (row.traffic_type === '1') {
                noon = noon + 1
                traffic_type_seq_noon = traffic_type_seq_noon + 1
            } else if (row.traffic_type === '2') {
                night = night + 1
                traffic_type_seq_night = traffic_type_seq_night + 1
            } else if (row.traffic_type === '4') {
                holidays = holidays + 1
                traffic_type_seq_Holidays = traffic_type_seq_Holidays + 1
            }
        })

        errorMessage += checkSelectTraffic(morning, checkList.morning, userInputData['morning'].checked, "朝")
        errorMessage += checkSelectTraffic(noon, checkList.noon, userInputData['noon'].checked, "昼")
        errorMessage += checkSelectTraffic(night, checkList.night, userInputData['night'].checked, "夜")
        errorMessage += checkSelectTraffic(holidays, checkList.holidays, userInputData['holidays'].checked, "休日")
        if (errorMessage) {
            const message: any = errorMessage.slice(0, -1).split("\n").map((line, key) => <span key={key}>{line}<br /></span>)
            if (utilityCtx.showSnackbar) {
                utilityCtx.showSnackbar("warning", message);
            }
            return
        }
        const requestParams: any = []
        list.map((row: any) => {
            if (row.morning === 1) {
                traffic_type_seq_morning += 1
                requestParams.push(addRequestParams(objLongPageParam, row, LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_0.typeVal, traffic_type_seq_morning.toString(), true))
            }
            if (row.noon === 1) {
                traffic_type_seq_noon += 1
                requestParams.push(addRequestParams(objLongPageParam, row, LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_1.typeVal, traffic_type_seq_noon.toString(), true))
            }
            if (row.night === 1) {
                traffic_type_seq_night += 1
                requestParams.push(addRequestParams(objLongPageParam, row, LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_2.typeVal, traffic_type_seq_night.toString(), true))
            }
            if (row.holidays === 1) {
                traffic_type_seq_Holidays += 1
                requestParams.push(addRequestParams(objLongPageParam, row, LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_4.typeVal, traffic_type_seq_Holidays.toString(), true))
            }
            requestParams.sort((a: any, b: any) => {
                return a.traffic_type - b.traffic_type
            })
        })
        let date = new Date();
        let count_date = ''
        if (userInputData['morning'].checked) {
            count_date = initCountDate(date, '9', '0')
            traffic_type_seq_morning += 1
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_0.typeVal, traffic_type_seq_morning.toString(), COUNT_TYPE_USER, count_date))
        }
        if (userInputData['noon'].checked) {
            count_date = initCountDate(date, '13', '0')
            traffic_type_seq_noon += 1
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_1.typeVal, traffic_type_seq_noon.toString(), COUNT_TYPE_USER, count_date))
        }
        if (userInputData['night'].checked) {
            count_date = initCountDate(date, '20', '0')
            traffic_type_seq_night += 1
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_2.typeVal, traffic_type_seq_night.toString(), COUNT_TYPE_USER, count_date))
        }
        if (userInputData['holidays'].checked) {
            count_date = initCountDate(date, '10', '30')
            traffic_type_seq_Holidays += 1
            requestParams.push(addTrafficParams(objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_4.typeVal, traffic_type_seq_Holidays.toString(), COUNT_TYPE_USER, count_date))
        }

        if (requestParams.length > 0) {
            const params = {
                mode: "regist",
                endPoint: "/location/Traffic/v1/regist",
                query: {
                    planAreaId: objLongPageParam.planAreaId,
                    branch: objLongPageParam.branch,
                    data: requestParams
                }
            };
            const locationTrafficparams = upsertLocationTraffic5mcutParam(objLocationTraffic5mcut, objLongPageParam.planAreaId.toString(), objLongPageParam.branch.toString(), objStation, objSchool)

            if (utilityCtx.showSpinner) {
                utilityCtx.showSpinner();
            }
            (async () => {
                try {
                    setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ADD_START_TRAFFIC_CAR);
                    setMessageDialogOpen(true);
                    await apiRequest(params);
                    await apiRequest(locationTrafficparams);
                    setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ADD_TRAFFIC_CAR);
                    setHasButton(true)
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                } catch (e) {
                    console.log(e);
                    setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ADD_ERROR_TRAFFIC_CAR);
                    setHasButton(true);
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                }
                try {
                    setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ADD_START_TRAFFIC_CAR);
                    setMessageDialogOpen(true);
                    await apiRequest(params);
                    await apiRequest(locationTrafficparams);
                    setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ADD_TRAFFIC_CAR);
                    setHasButton(true)
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                } catch (e) {
                    console.log(e);
                    setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ADD_ERROR_TRAFFIC_CAR);
                    setHasButton(true);
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                }
            })();
        }
    }

    const closeMessageDialog = () => {
        setMessage("");
        setHasButton(false);
        setMessageDialogOpen(false);
        // 閉じる
        handleCloseRegist()
    };

    const handleUserInputDisabled = (key: string) => {
        return userInputData[key].disabled
    }

    const handleUserInputChecked = (key: string) => {
        return userInputData[key].checked
    }

    const handleUserInputCheck = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
        userInputData[key].checked = e.target.checked
        setUserInputData(userInputData)
        setRedo(!redo)
    }

    const handleSelectedChange = (e: SelectChangeEvent<string>) => {
        setSeparateValue(e.target.value)
    }

    React.useEffect(() => {
        switch (separateValue) {
            case '0':
                userInputData['night'].disabled = true
                userInputData['night'].checked = false
                userInputData['holidays'].disabled = true
                userInputData['holidays'].checked = false
                break
            case '1':
                userInputData['night'].disabled = false
                userInputData['night'].checked = false
                userInputData['holidays'].disabled = true
                userInputData['holidays'].checked = false
                break
            case '2':
                userInputData['night'].disabled = true
                userInputData['night'].checked = false
                userInputData['holidays'].disabled = false
                userInputData['holidays'].checked = false
                break
            case '3':
                userInputData['night'].disabled = false
                userInputData['night'].checked = false
                userInputData['holidays'].disabled = false
                userInputData['holidays'].checked = false
                break
            default:
                break
        }
        if (list) {
            removeChecked(list, checkList)
        }
        dispatch(
            setObjLocationTraffic5mcut({
                ...objLocationTraffic5mcut,
                ['car_another_survey']: separateValue
            }))
        setUserInputData(userInputData)
        setRedo(!redo)

    }, [separateValue])

    const removeChecked = (list: any, checkList: any) => {
        list.map((row: any) => {
            row.night = 0
            row.holidays = 0
        })
        checkList.night = 0
        checkList.holidays = 0
        setList(list)
        setCheckList(checkList)
    }

    return (
        <>
            <Box sx={{ padding: '20px', width: '100%', height: '75%', minHeight: '75%', maxHeight: '75%' }}>
                <Box sx={{ width: '100%', height: '75%', minHeight: '75%', maxHeight: '75%' }}>
                    <Box sx={{ width: '98%', height: '120px', textAlign: 'center', display: 'flex', backgroundColor: '#D9D9D9', color: "#fff" }}>
                        <Box sx={{ width: '20%', minWidth: '20%', maxWidth: '20%', height: '100%' }}>
                            <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.COUNT_DATE_TIME}</Typography>
                            <Box sx={{ display: 'flex', height: '30%' }}>
                                <Typography sx={borderStyleColor(50, 40)}>{LOCAL_CONSTANT.LABEL.DATE_AND_TIME}</Typography>
                                <Typography sx={borderStyleColor(50, 40)}>{LOCAL_CONSTANT.LABEL.TIME}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ width: '60%', minWidth: '60%', maxWidth: '60%', display: 'flex' }}>
                            <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', height: '100%', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.FRONT}</Typography>
                                <Box sx={{ display: 'flex', height: '30%' }}>
                                    <Typography sx={borderStyleColor(34, 40)}>{LOCAL_CONSTANT.LABEL.STANDARD}</Typography>
                                    <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LIGHT}</Typography>
                                    <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LARGE}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', height: '100%', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.SIDE_A}</Typography>
                                <Box sx={{ display: 'flex', height: '30%' }}>
                                    <Typography sx={borderStyleColor(34, 40)}>{LOCAL_CONSTANT.LABEL.STANDARD}</Typography>
                                    <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LIGHT}</Typography>
                                    <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LARGE}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', height: '100%', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.SIDE_B}</Typography>
                                <Box sx={{ display: 'flex', height: '30%' }}>
                                    <Typography sx={borderStyleColor(34, 40)}>{LOCAL_CONSTANT.LABEL.STANDARD}</Typography>
                                    <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LIGHT}</Typography>
                                    <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LARGE}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', height: '100%', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.BACK}</Typography>
                                <Box sx={{ display: 'flex', height: '30%' }}>
                                    <Typography sx={borderStyleColor(34, 40)}>{LOCAL_CONSTANT.LABEL.STANDARD}</Typography>
                                    <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LIGHT}</Typography>
                                    <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LARGE}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ width: '20%', height: '100%', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                            <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.SELECT_COUNT_DATE}</Typography>
                            <Box sx={{ display: 'flex', height: '30%' }}>
                                <Typography sx={borderStyleColor(25, 40)}>{LOCAL_CONSTANT.LABEL.MORNING}</Typography>
                                <Typography sx={borderStyleColor(25, 40)}>{LOCAL_CONSTANT.LABEL.NOON}</Typography>
                                <Typography sx={borderStyleColor(25, 40)}>{LOCAL_CONSTANT.LABEL.NIGHT}</Typography>
                                <Typography sx={borderStyleColor(25, 40)}>{LOCAL_CONSTANT.LABEL.HOLIDAYS}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ textAlign: 'center', width: '100%', overflow: 'scroll', height: '90%', minHeight: '90%', maxHeight: '90%' }}>
                        <Box sx={{ width: '99.6%' }}>
                            <Box sx={{ display: 'flex', height: '50px', '& input': { padding: '14px 0', textAlign: 'center' } }}>
                                <Box sx={{ width: '20%', minWidth: '20%', maxWidth: '20%', display: 'flex' }}>
                                    <Typography sx={borderStyleDisabled(50, 50)}>{LOCAL_CONSTANT.LABEL.MANUAL_INPUT}</Typography>
                                    <Typography sx={borderStyleDisabled(50, 50)}></Typography>
                                </Box>
                                <Box sx={{ width: '60%', minWidth: '60%', maxWidth: '60%', display: 'flex' }}>
                                    <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                        <Typography sx={borderStyleDisabled(34, 50)}></Typography>
                                        <Typography sx={borderStyleDisabled(33, 50)}></Typography>
                                        <Typography sx={borderStyleDisabled(33, 50)}></Typography>

                                    </Box>
                                    <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                        <Typography sx={borderStyleDisabled(34, 50)}></Typography>
                                        <Typography sx={borderStyleDisabled(33, 50)}></Typography>
                                        <Typography sx={borderStyleDisabled(33, 50)}></Typography>
                                    </Box>
                                    <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                        <Typography sx={borderStyleDisabled(34, 50)}></Typography>
                                        <Typography sx={borderStyleDisabled(33, 50)}></Typography>
                                        <Typography sx={borderStyleDisabled(33, 50)}></Typography>

                                    </Box>
                                    <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                        <Typography sx={borderStyleDisabled(34, 50)}></Typography>
                                        <Typography sx={borderStyleDisabled(33, 50)}></Typography>
                                        <Typography sx={borderStyleDisabled(33, 50)}></Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ width: '20%', minWidth: '20%', maxWidth: '20%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                    {
                                        Object.keys(userInputData).map((key) => (
                                            <Checkbox
                                                key={key}
                                                // disabled={handleUserInputDisabled(key)}
                                                sx={checkBoxStyle([], false, key)}
                                                checked={handleUserInputChecked(key)}
                                                onChange={(e) => { handleUserInputCheck(e, key) }}
                                            />
                                        ))
                                    }
                                </Box>
                            </Box>
                            {list.map((row: any) => (
                                <Box
                                    key={row.uuid}
                                    sx={{ display: 'flex', height: '50px' }}
                                >
                                    <Box sx={{ width: '20%', minWidth: '20%', maxWidth: '20%', display: 'flex' }}>
                                        <Typography sx={borderStyle(50, 50)}>{dateDisplayByType(row, "", "yearday")}</Typography>
                                        <Typography sx={borderStyle(50, 50)}>{timeDisplay(row, "")}</Typography>
                                    </Box>
                                    <Box sx={{ width: '60%', minWidth: '60%', maxWidth: '60%', display: 'flex' }}>
                                        <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', display: 'flex', backgroundColor: hasBC(row), borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                            <Typography sx={borderStyle(34, 50)}>{row.car_front_standard}</Typography>
                                            <Typography sx={borderStyle(33, 50)}>{row.car_front_light}</Typography>
                                            <Typography sx={borderStyle(33, 50)}>{row.car_front_large}</Typography>
                                        </Box>
                                        <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', display: 'flex', backgroundColor: hasBC(row), borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                            <Typography sx={borderStyle(34, 50)}>{row.car_side_a_standard}</Typography>
                                            <Typography sx={borderStyle(33, 50)}>{row.car_side_a_light}</Typography>
                                            <Typography sx={borderStyle(33, 50)}>{row.car_side_a_large}</Typography>
                                        </Box>
                                        <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', display: 'flex', backgroundColor: hasBC(row), borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                            <Typography sx={borderStyle(34, 50)}>{row.car_side_b_standard}</Typography>
                                            <Typography sx={borderStyle(33, 50)}>{row.car_side_b_light}</Typography>
                                            <Typography sx={borderStyle(33, 50)}>{row.car_side_b_large}</Typography>
                                        </Box>
                                        <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', display: 'flex', backgroundColor: hasBC(row), borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                            <Typography sx={borderStyle(34, 50)}>{row.car_back_standard}</Typography>
                                            <Typography sx={borderStyle(33, 50)}>{row.car_back_light}</Typography>
                                            <Typography sx={borderStyle(33, 50)}>{row.car_back_large}</Typography>
                                        </Box>
                                    </Box>
                                    <Box sx={{ width: '20%', minWidth: '20%', maxWidth: '20%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                        {checkBoxList().map((listRow: any) => (
                                            <Checkbox
                                                key={row.uuid + '_' + listRow.key}
                                                checked={hasChecked(row[listRow.key])}
                                                // disabled={hasDisabled(row, listRow.key, userInputData, separateValue)}
                                                sx={checkBoxStyle(row, true, listRow.key)}
                                                onChange={(e) => { handleChange(e, row, listRow.key) }}
                                            />
                                        ))}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', padding: '10px' }}>
                <FormLabel sx={{ padding: '14px 0' }}>別途調査必要時（該当を選択）</FormLabel>
                <Select
                    onChange={(e) => handleSelectedChange(e)}
                    value={separateValue}
                >
                    {
                        separateInvestigation?.map((row: any) => (
                            <MenuItem key={row.code_value} value={row.code_value}>{row.code_display}</MenuItem>
                        ))
                    }
                </Select>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', bottom: '0', padding: '10px' }}>
                <Button variant="outlined" color="error" size="large" onClick={handleClose}>{LOCAL_CONSTANT.LABEL.CANCEL}</Button>
                <Button variant="contained" color="success" size="large" onClick={handleRegist}>{LOCAL_CONSTANT.LABEL.ADD}</Button>
            </Box>
            <MessageDialog props={{
                open: messageDialogOpen,
                handleModal: closeMessageDialog,
                body: message,
                hasButton: hasButton,
                buttonBody: LOCAL_CONSTANT.LABEL.CLOSE
            }}
            />
        </>
    );
}

export default AddCar;