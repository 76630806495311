import * as React from 'react';

import {
    Box,
    Button,
    Typography,
} from '@mui/material';
import { useUtilityContext } from '../../../utility-provider';
import { apiRequest } from "../../../api/ApiCall";
import {
    dateDisplayByType,
    timeDisplay,
    borderStyle,
    borderStyleColor,
    checkTrafficTime,
    changeParams,
} from "./TrafficVolumeFunction";
import { LOCAL_CONSTANT } from "../../Const";
import { useAppSelector } from '../../../app/hooks';
import MessageDialog from '../../components/MessageDialog';
import ConfirmDialog from '../../components/ConfirmDialog';
import { locationTrafficObj } from '../../../data/type';

export interface addCarProps {
    props: {
        trafficVolumeList: any;
        handleClose: any;
        handleCloseRegist: any;
        targetTraffic: any;
    }
}

const ChangeCar: React.FC<addCarProps> = ({ props }) => {
    const utilityCtx = useUtilityContext();

    const [list, setList] = React.useState([]);
    const [innerWidth, setInnerWidth] = React.useState("");

    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    const [message, setMessage] = React.useState<string>("");
    const [messageDialogOpen, setMessageDialogOpen] = React.useState<boolean>(false);
    const [hasButton, setHasButton] = React.useState<boolean>(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(false);
    const [changeTraffic, setChangeTraffic] = React.useState(locationTrafficObj)

    React.useEffect(() => {
        setInnerWidth((Math.round(Number(window.innerWidth) * 0.85)).toString() + 'px')
    }, [window.innerWidth])

    // 閉じる
    const handleClose = () => {
        props.handleClose()
    };

    const handleCloseRegist = () => {
        props.handleCloseRegist()
    };

    React.useEffect(() => {
        const tmpCarList: any = []
        props.trafficVolumeList.map((row: any) => {
            tmpCarList.push(row)
        })
        setList(tmpCarList)
    }, [props.trafficVolumeList])

    const closeMessageDialog = () => {
        setMessage("");
        setHasButton(false);
        setMessageDialogOpen(false);
        // 閉じる
        handleCloseRegist()
    };

    const changeHandle = (row: any) => {
        setChangeTraffic(row)
        if (checkTrafficTime(row, props.targetTraffic) !== "#FFFFFF") {
            setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.CHANGE_TRAFFIC_CONFIRM)
            setConfirmDialogOpen(true)
        } else {
            changeResult()
        }
    }

    const changeCancel = () => {
        setChangeTraffic(locationTrafficObj)
        setConfirmDialogOpen(false)
        setMessage("")
    }

    const changeResult = () => {
        const params = changeParams(changeTraffic, props.targetTraffic, objLongPageParam.planAreaId, true)
        changeRegist(params)
    }

    const changeRegist = (params: any) => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UPDATE_START_TRAFFIC_CAR);
                setMessageDialogOpen(true);
                await apiRequest(params);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UPDATE_TRAFFIC_CAR);
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.UPDATE_ERROR_TRAFFIC_CAR);
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    }


    return (
        <>
            <Box sx={{ padding: '20px', width: innerWidth, height: '75%', minHeight: '75%', maxHeight: '75%' }}>
                <Box sx={{ width: '100%', height: '75%', minHeight: '75%', maxHeight: '75%' }}>
                    <Box sx={{ position: 'relation' }}>
                        <Box sx={{ width: '98%', height: '120px', textAlign: 'center', display: 'flex', backgroundColor: '#D9D9D9', color: "#fff" }}>
                            <Box sx={{ width: '20%', minWidth: '20%', maxWidth: '20%', height: '100%' }}>
                                <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.COUNT_DATE_TIME}</Typography>
                                <Box sx={{ display: 'flex', height: '30%' }}>
                                    <Typography sx={borderStyleColor(50, 40)}>{LOCAL_CONSTANT.LABEL.DATE_AND_TIME}</Typography>
                                    <Typography sx={borderStyleColor(50, 40)}>{LOCAL_CONSTANT.LABEL.TIME}</Typography>
                                </Box>
                            </Box>
                            <Box sx={{ width: '70%', minWidth: '70%', maxWidth: '70%', display: 'flex' }}>
                                <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', height: '100%', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                    <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.FRONT}</Typography>
                                    <Box sx={{ display: 'flex', height: '30%' }}>
                                        <Typography sx={borderStyleColor(34, 40)}>{LOCAL_CONSTANT.LABEL.STANDARD}</Typography>
                                        <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LIGHT}</Typography>
                                        <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LARGE}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', height: '100%', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                    <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.SIDE_A}</Typography>
                                    <Box sx={{ display: 'flex', height: '30%' }}>
                                        <Typography sx={borderStyleColor(34, 40)}>{LOCAL_CONSTANT.LABEL.STANDARD}</Typography>
                                        <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LIGHT}</Typography>
                                        <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LARGE}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', height: '100%', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                    <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.SIDE_B}</Typography>
                                    <Box sx={{ display: 'flex', height: '30%' }}>
                                        <Typography sx={borderStyleColor(34, 40)}>{LOCAL_CONSTANT.LABEL.STANDARD}</Typography>
                                        <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LIGHT}</Typography>
                                        <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LARGE}</Typography>
                                    </Box>
                                </Box>
                                <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', height: '100%', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                    <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.BACK}</Typography>
                                    <Box sx={{ display: 'flex', height: '30%' }}>
                                        <Typography sx={borderStyleColor(34, 40)}>{LOCAL_CONSTANT.LABEL.STANDARD}</Typography>
                                        <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LIGHT}</Typography>
                                        <Typography sx={borderStyleColor(33, 40)}>{LOCAL_CONSTANT.LABEL.LARGE}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ width: '10%', minWidth: '10%', maxWidth: '10%', height: '100%', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                <Typography sx={borderStyleColor(100, 80)}>{LOCAL_CONSTANT.LABEL.SELECT}</Typography>
                            </Box>
                        </Box>
                        <Box sx={{ textAlign: 'center', width: '99%', minWidth: '100%', maxWidth: '100%', height: '90%', minHeight: '90%', maxHeight: '90%', overflow: 'scroll' }}>
                            <Box sx={{ width: '98%' }}>
                                {list.map((row: any) => (
                                    <Box
                                        key={row.uuid}
                                        sx={{ display: 'flex', width: '100%', height: '50px', backgroundColor: checkTrafficTime(row, props.targetTraffic) }}
                                    >
                                        <Box sx={{ width: '20.3%', minWidth: '20.3%', maxWidth: '20.3%', display: 'flex' }}>
                                            <Typography sx={borderStyle(50, 50)}>{dateDisplayByType(row, "", "yearday")}</Typography>
                                            <Typography sx={borderStyle(50, 50)}>{timeDisplay(row, "")}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', width: '71.1%', minWidth: '71.1%', maxWidth: '71.1%' }}>
                                            <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                                <Typography sx={borderStyle(34, 50)}>{row.car_front_standard}</Typography>
                                                <Typography sx={borderStyle(33, 50)}>{row.car_front_light}</Typography>
                                                <Typography sx={borderStyle(33, 50)}>{row.car_front_large}</Typography>
                                            </Box>
                                            <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                                <Typography sx={borderStyle(34, 50)}>{row.car_side_a_standard}</Typography>
                                                <Typography sx={borderStyle(33, 50)}>{row.car_side_a_light}</Typography>
                                                <Typography sx={borderStyle(33, 50)}>{row.car_side_a_large}</Typography>
                                            </Box>
                                            <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                                <Typography sx={borderStyle(34, 50)}>{row.car_side_b_standard}</Typography>
                                                <Typography sx={borderStyle(33, 50)}>{row.car_side_b_light}</Typography>
                                                <Typography sx={borderStyle(33, 50)}>{row.car_side_b_large}</Typography>
                                            </Box>
                                            <Box sx={{ width: '25%', minWidth: '25%', maxWidth: '25%', display: 'flex', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                                <Typography sx={borderStyle(34, 50)}>{row.car_back_standard}</Typography>
                                                <Typography sx={borderStyle(33, 50)}>{row.car_back_light}</Typography>
                                                <Typography sx={borderStyle(33, 50)}>{row.car_back_large}</Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ width: '10%', minWidth: '10%', maxWidth: '10%', padding: '10px 0', borderLeft: "solid 2px rgba(100, 100, 100, 1)" }}>
                                            <Button
                                                variant="contained"
                                                color="success"
                                                size="small"
                                                onClick={() => changeHandle(row)}
                                            >入替</Button>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-around', bottom: '0', padding: '10px' }}>
                <Button variant="outlined" color="error" size="large" onClick={handleClose}>{LOCAL_CONSTANT.LABEL.CANCEL}</Button>
            </Box>
            <MessageDialog props={{
                open: messageDialogOpen,
                handleModal: closeMessageDialog,
                body: message,
                hasButton: hasButton,
                buttonBody: LOCAL_CONSTANT.LABEL.CLOSE
            }}
            />
            <ConfirmDialog props={{
                open: confirmDialogOpen,
                handleModal: setConfirmDialogOpen,
                onCancel: changeCancel,
                onExecution: changeResult,
                mode: "changeConfirm",
                body: message,
                confirmCancel: LOCAL_CONSTANT.LABEL.CANCEL,
                confirmOk: LOCAL_CONSTANT.LABEL.UPDATE
            }}
            />
        </>
    );
}

export default ChangeCar;